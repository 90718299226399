@import "_base.scss";

.settingsParentContainer {
  padding: 0 0 1.125rem 1.1rem;
  background: $color-light-grey;
  height: 100vh;
  background: #F8FAFF;
  font-size: 14px;
}

.loadingComponentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsSection {
  // display: grid;
  // grid-auto-flow: column;
  // grid-template-columns: 10% auto 3fr;
  display: flex;
  flex-direction: row;
}

.settingsContent {
  background: #ffffff;
  border-radius: 60px 0px 0px 60px;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}

.settingsGrid {
  display: grid;
  grid-template-columns: 4fr 9fr;
  margin-top: 1.5rem;
  grid-gap: 1.5rem;
}

.largeNavigationBar {
  display: block;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.optionsSetion {
  background: #F8F9FB;
  border-radius: 25px 25px 0px 0px;
  padding: 1.56rem 1.56rem;
}

.selectedOptionSection {
  background: #F8F9FB;
  border-radius: 0px 0px 25px 25px;
  padding: 1.56rem 1.56rem;
}

.publicProfileText {
  color: #1C2640;
  font-weight: 500;
  font-size: 1.08em;
  line-height: 21px;
}

.profileVisibiltyText {
  color: #5F6981;
  font-weight: 400;
  font-size: .96em;
  line-height: 18px;
  margin-top: .2rem;
}

.closeAccountOption {
  background: #FFFFFF;
  border: 1px solid #EEF0F5;
  box-shadow: 0px 2px 24px rgba(131, 134, 140, .05);
  border-radius: 12px;
  padding: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.closeAccountText {
  color: #5F6981;
  font-weight: 500;
  font-size: 1em;
  line-height: 32px;
}

.closeCandidateAccountText {
  color: #1C2640;
  font-weight: 600;
  font-size: 1.6em;
  line-height: 35px;
}

.confirmationSection {
  margin-top: 1rem;
}

.primaryConfirmationText {
  color: #000000;
  font-weight: 600;
  font-size: 1.46em;
  line-height: 34px;
}

.secondaryConfirmationText {
  color: #5F6981;
  font-weight: 400;
  font-size: 1.124em;
  line-height: 33px;
}

.reasonSection {
  margin-top: 1rem;
}

.reasoningHeading {
  color: #000000;
  font-weight: 600;
  font-size: 1.46em;
  line-height: 34px;
}

.reasonOptionsSection {
  margin-top: .8rem;
}

.reasonOptions {
  display: block;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: .8rem;
}

.centerResponse {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9BA4BA;
  font-weight: 500;
  font-size: 1.07em;
  line-height: 32px;
}

.checkboxInputField {
  position: relative;
  bottom: 1.1px;
  cursor: pointer;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #FFFFFF;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #9BA4BA;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #6196FE;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #6196FE;
}

.feedbackSection {
  margin-top: 1rem;
}

.primaryFeedbackText {
  color: #5F6981;
  font-weight: 500;
  line-height: 30px;
  font-size: 1.071em;
}

.hyperLink {
  color: #6196FE;
  font-weight: 500;
  line-height: 30px;
  font-size: 1.071em;
  cursor: pointer;
}

.hyperLink:hover {
  text-decoration: underline;
}

.buttonSection {
  margin-top: 1rem;
}

.buttonSectionPrimaryText {
  color: #000000;
  font-weight: 500;
  font-size: 1.081em;
  line-height: 35px;
}

.confirmationButtons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.deleteButton {
  background-color: #F3F4F8;
  padding: .8rem 1.5rem;
  border-radius: 12px;
  border: 0;
  color: #7989B1;
}

.deleteButton:hover {
  background-color: #edeff7;
}

.deleteButton:focus {
  outline: none;
}

.confirmButton {
  background-color: #6196FE;
  padding: .8rem 1.5rem;
  border-radius: 12px;
  border: 0;
  color: #FFFFFF;
}

.confirmButton:hover {
  background-color: #7aa6ff;
}

.confirmButton:focus {
  outline: none;
}

.mobileScreenHeader {
  display: none;
}

.hamburgerMenuDiv {
  display: inline-flex;
  align-items: center;
}

.largeSettingsText {
  display: block;
}

@media screen and (max-width: 956px) {

  .largeNavigationBar {
    display: none;
  }

  .settingsParentContainer {
    padding: 0;
  }

  .settingsContent {
    border-radius: 0;
    padding: 1.5rem 1.2rem;
  }

  .mobileScreenHeader {
    display: grid;
    grid-template-columns: 3fr 6fr 3fr;
  }

  .settingsText {
    text-align: center;
    font-weight: 600;
    line-height: 45px;
    color: #080B2E;
    font-size: 1.5em;
  }

  .settingsGrid {
    display: block;
  }

  .largeSettingsText {
    display: none;
  }

  .publicProfileText {
    font-size: 1.05em;
  }

  .profileVisibiltyText {
    font-size: .97em;
  }

  .optionsSetion {
    padding: 1.3rem;
  }

  .selectedOptionSection {
    padding: 1.3rem;
    margin-bottom: 1.2rem;
  }

  .closeAccountText {
    font-size: 1.05em;
  }

  .closeAccountOption {
    padding: 1rem;
  }

  .closeCandidateAccountText {
    font-size: 1.5em;
  }

  .primaryConfirmationText,
  .reasoningHeading {
    font-size: 1.3em;
    line-height: 30px;
  }

  .secondaryConfirmationText {
    margin-top: .7rem;
    line-height: 30px;
    font-size: 1.1em;
  }

  .centerResponse {
    font-size: 1em;
    line-height: 30px;
  }

  .primaryFeedbackText {
    font-size: 1em;
    line-height: 30px;
  }

  .hyperLink {
    font-size: 1em;
    line-height: 30px;
  }

  .buttonSectionPrimaryText {
    font-size: 1.1em;
    line-height: 30px;
  }

  .confirmationButtons {
    margin-bottom: 1rem;
  }

  input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #FFFFFF;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #9BA4BA;
  }
  
  input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #6196FE;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #6196FE;
  }
}