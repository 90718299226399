
.dashboardPanelConatainer {
  background: #ffffff;
  border-radius: 60px 0px 0px 60px;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;
}

.mobileDashboardPanelConatainer {
  display: none;
}

.introVideoContainer {
  position: relative;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  background: linear-gradient(95.96deg, rgba(234, 240, 252, .3) .24%, #e3ecff 100%);
  height: max-content;
  padding: 1.2rem 2rem;
  border-radius: 20px;
  border: 1px solid #d3e2ff;
}

.ProfileLogo {
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 6px 44px rgba(185, 185, 218, .3);
  border-radius: 25px;
  padding: 1.5rem;
  margin-right: 1rem;
}

.introVideoRecordButton {
  background: #6196FE;
  color: #FFFFFF;
  padding: 1.5rem;
  font-size: 1em;
  letter-spacing: -.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 10px;
  max-height: 1rem;
  min-height: 1rem;
  font-weight: 300;
  letter-spacing: .01em;
  font-weight: 400;
}

.greetings {
  font-weight: 100;
  font-size: 1.2em;
}

.candidateName {
  color: #6196FE;
  font-size: 1.2em;
  font-weight: 500;
  margin-left: .3em;
}

.introText {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.5em;
  color: #1C2640;
  font-weight: 500;
}

.mobileScreenHeader {
  display: none;
}

.mobileNavigationBar {
  display: none;
}

.hamburgerMenuDiv {
  display: inline-flex;
  align-items: center;
}

.designIcon1 {
  position: relative;
  bottom: 1.5rem;
}

.designIcon2 {
  position: relative;
  top: 1.5rem;
  right: 1rem;
  transform: rotate(180deg);
}

.firstSection {
  background-color: #FFFFFF;
  padding: 1.5rem 1.2rem 0rem 1.2rem;
}

.InterviewsButton {
  text-align: center;
  color: #1C2640;
  font-weight: 600;
  font-size: 1.1em;
  padding: 1.2rem;
  // border-bottom: 2px solid 
}

.selectedUnderLine {
  background-color: #FE5819;
  width: 35%;
  height: 3px;
  margin: auto;
  border-radius: 3px 3px 0px 0px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upButton {
  display: none;
}

.candidateThumbnail {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  cursor: pointer;
  border-radius: 16px;
}

.candidateThumbnail .squircle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.noCandidateThumbnail {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  padding: .5rem;
  cursor: pointer;
  border-radius: 16px;
  background-color: #f7f7f7;
}

.noCandidateThumbnail .squircle {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (min-width: 1441px) {

  .introVideoContainer {
    font-size: 1.2em;
  }

  .introText {
    font-size: 1.8em;
    line-height: 1.5em;
    font-weight: 500;
    color: #1C2640;
  }

  .candidateName {
    font-weight: 500;
    margin-left: .3em;
  }

  .greetingDocument {
    flex: 1;
  }

  .recordNowButton {
    font-size: 1em;
    background: #EFF5FF;
    color: #6196FE;
    border: 1px solid #6196FE;
    border-radius: 10px;
    padding: .6em .5em;
  }
  
}


@media screen and (max-width: 1352px) {
  
  .introText {
    font-weight: bold;
    font-size: 1.4em;
    line-height: 1.5em;
    color: #1C2640;
    font-weight: 500;
  }
}


@media screen and (max-width: 956px) {

  .dashboardPanelConatainer {
    display: none;
  }
  
  .mobileDashboardPanelConatainer {
    display: block;
    background: #F8FAFF;
    border-radius: 0;
  }

  .largeScreenHeader {
    display: none;
  }
  
  .mobileScreenHeader {
    display: grid;
    grid-template-columns: 3fr 6fr 3fr;
  }

  .mobileNavigationBar {
    display: block;
  }

  .dashboardText {
    text-align: center;
    font-weight: 600;
    line-height: 45px;
    color: #080B2E;
    font-size: 1.2em;
  }

  .notRespondedIntroVideoContainer {
    background: linear-gradient(95.96deg, rgba(234, 240, 252, .3) .24%, #e3ecff 100%);
    border-radius: 25px;
    padding: 2rem 1.5rem;
    display: block;
    margin-top: 1.5rem;
  }

  .greetings {
    color: #7989B1;
    font-weight: 400;
    font-size: 1.25em;
    line-height: 38px;
  }

  .candidateName {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 38px;
  }

  .introVideoContainer {
    padding: 1.5rem;
  }
  
  .introText {
    font-weight: bold;
    font-size: 1.27em;
    line-height: 1.5em;
    color: #1C2640;
    font-weight: 500;
  }

  .upButton {
    display: block;
    text-align: center;
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    color: #6196FE;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 1rem;
    z-index: 5;
    background: #FFFFFF;
    box-shadow: 10px 10px 15px rgba(70, 70, 70, .12);
    padding: 1rem;
    border-radius: 50px;
  }
}