@import "./_common.scss";

@font-face {
  font-family: "Campton";
  src: local("Campton"), url(./fonts/Campton-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Campton";
  src: local("Campton"), url(./fonts/Campton-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sofia-Pro";
  src: local("Sofia-Pro"), url(./fonts/Sofia-Pro-Light.otf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sofia-Pro";
  src: local("Sofia-Pro"), url(./fonts/Sofia-Pro-Medium.otf) format("opentype");
  font-weight: 500;
  font-style: normal;
}


body {
  background-color: #f8f9fb;
  margin: 0;
  padding: 0;
  font-family: "Sofia-Pro","Campton", Helvetica, Arial, sans-serif;
  height: 100%;
  // font-size: 100%;  

  iframe {
    display: none;
  }
}
