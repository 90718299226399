.bannerContainerNew {
  width: 100%;
  height: 18em;
  margin-bottom: 3em;
  position: relative;
  
  img {
    width: 100%;
    height: 100%;
    
  }

  .leftHeadingContainer {
    background-color: #6196FE;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
    width: 21.5rem;
    position: absolute;
    top: 0%;
    height: 100%;
    z-index: 3;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }

  .leftHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding-right: .5em;
    padding-top: .5em;
    
    h5 {
      font-size: 1.6em;
      font-weight: 600;
    }

    span {
      font-weight: 100;
    }
  }
}

.rightHeadingContainer {
  position: absolute;
  right: 0;
  height: 100%;
  background: linear-gradient(270deg, #000000 90%, rgba(0, 0, 0, 0) 100%);
  // background-color: #000000;
  color: #FFFFFF;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;

}

.rightHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lineThrough {
  text-decoration-line: line-through;
}

.bannerImageDrawerOpen {
  left: -3rem !important;
  width: 56rem !important;
}

.rightHeadingContainerDrawerOpen {
  justify-content: right !important;
  padding-right: 6% !important;
  align-items: center !important;
  width: 44% !important;
}

@media screen and (max-width: 1920px) {

  .bannerContainerNew {
    
    .leftHeadingContainer {
      width: 45%;
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 122%, 79% 0);
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 100%, 79% 0);
    }

    .bannerImage {
      // object-fit: cover;
      left: 0%;
    }

    .rightHeadingContainer {
      width: 40%;
      background: linear-gradient(270deg, #000000 90%, rgba(0, 0, 0, 0) 100%);
    }
  }


  .bannerImageDrawerOpen {
    left: -3% !important;
    width: 100% !important;
    z-index: 5;
  }

  .rightHeadingContainerDrawerOpen {
    justify-content: right !important;
    padding-right: 6% !important;
    align-items: center !important;
    width: 46% !important;
  }
}