* {
  padding: 0;
  margin: 0;
}

.parentContainer {
  overflow-x: hidden;
}

.topNotificationSection {
  background-color: #08152A;
  color: #FFFFFF;
  text-align: center;
  padding: .5rem 0rem;
}

.notificationText {
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 1em;
  justify-content: center;
  margin-left: .5rem;
}

.topContentSection {
  background-color: #FFFFFF;
}

.navigationBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.navItems {
  display: flex;
  gap: 2rem !important;
  margin-left: 7rem !important;
}

.navItem {
  color: rgba(0,0,0,.5)  !important;
  font-weight: 500 !important;
  font-size: 1.08em !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  transition: transform .2s;
}

.navItem:hover {
  color: rgba(0,0,0,.7) !important;
  cursor: pointer !important;
  transform: scale(1.05);
}

.navItemDropdown {
  color: #1C2640 !important;
  font-weight: 500 !important;
  font-size: 1.08em !important;
  line-height: 18px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .2s !important;
  float: left;
  overflow: hidden;
}

.navItemDropdown:hover {
  color: #7A91AB !important;
  cursor: pointer !important;
  transform: scale(1.05) !important;
}

.navItemDropdown:hover .navDropdownContent {
  display: block;
}

.navDropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,.2);
  z-index: 1;
  color: black;
  top: 3rem;
}

.navDropdownContent a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.navDropdownContent a:hover {
  background-color: #ddd;
}

.navigationButtons {
  display: flex;
  gap: 1rem;
  margin-right: 9rem;
}

.loginButton {
  color: #1C2640;
  border: 1.2px solid #BAC2D1;
  border-radius: 6px;
  background-color: #F8FAFF;
  padding: .6rem 1.2rem;
  font-weight: 500;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButton:hover {
  background-color: #fdfdfd;
}

.loginButton:focus {
  outline: none;
}

.InterviewerText {
  display: none;
}

.signupBannerBtnSection {
  display: flex;
  margin-top: 1rem;
}

.bannerSignupBtn {
  color: #457CE8;
  background-color: white;
  border: 0;
  padding: .9rem 1.5rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bannerSignupBtn:hover {
  background-color: rgb(255, 249, 249);
}

.bannerSignupBtn:focus {
  outline: none;
}

.signupButton {
  color: #FFFFFF;
  border: 0;
  border-radius: 6px;
  background-color: #6196FE;
  padding: .6rem 1.2rem;
  font-weight: 500;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signupButton:hover {
  background-color: #7da6f9;
}

.signupButton:focus {
  outline: none;
}

.selectLanguageButton {
  color: #1C2640;
  border: 1.2px solid #BAC2D1;
  border-radius: 6px;
  background-color: #F8FAFF;
  font-weight: 500;
  font-size: 1em;
}

.topSectionContent {
  padding: 7rem 0rem;
}

.textContainer {
  display: inline-block;
  text-align: end;
}

.center {
  display: flex;
  justify-content: center;
}

.companyHiringText {
  color: #222E50;
  font-weight: 700;
  font-size: 5em;
}

.heroText {
  margin-top: 1.5rem;
  font-weight: 400;
  font-size: 1.12em;
  line-height: 26px;
  width: 56%;
  text-align: center;
  color: #7A91AB;
}

.searchboxContainer {
  margin-top: 1.5rem;
  padding: .3rem;
  background-color: #F8F9FB;
  border: 1px solid #E4E8F0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform .2s !important;
}

.searchboxContainer:hover {
  transform: scale(1.02) !important;
}

// .searchBox {
//   border: none;
//   background-color: #F8F9FB;
// }

.searchButton {
  background-color: #6196FE;
  border-radius: 6px;
  color: white;
  padding: .6rem 1.2rem;
  border: 0;
  margin-left: 3rem;
}

.searchButton:hover {
  background-color: #4580f7;
}

.searchButton:focus {
  outline: none;
}

form.searchBox input[type=text] {
  padding: .5rem;
  font-size: 1em;
  border: 0;
  max-width: 100%;
  background: #F8F9FB;
}

.inputSearch:focus {
  outline: none;
}

.jobCardsSection {
  background-color: #F8FAFF;
  padding: 2rem 0rem 4rem 0rem;
}

.jobCardsHeading {
  color: #222E50;
  font-weight: 700;
  font-size: 3.1em;
  text-align: center;
  line-height: 50px;
  margin-top: 1.8rem;
}

.jobCardsText {
  text-align: center;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #7A91AB;
  width: 50%;
  margin-top: 1rem;
}

.jobCardsContainer {
  padding: 0rem 8rem;
  margin: 1rem auto;
}

.cardGrid {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  gap: 1rem 2rem;
}

.cardContainer {
  background: #FFFFFF;
  border-radius: 30px;
  padding: 1.5rem 1.2rem;
  transition: transform .2s !important;
  min-width: 100%;
}

.paginationBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.cardContainer:hover {
  transform: scale(1.01) !important;
  background: rgba(97, 150, 254, .3);
  transition: all 2s linear;

  .companyCareerPageSection {
    background: #FFFFFF;
  }

  .companyPageText {
    color: #6196FE;
    text-decoration: none !important;
  }

  .longArrow {
    color: #6196FE;
  }

  .activeJobContainer {
    border: 1px solid white;
    background: rgba(255, 255, 255, .1);
  }

  .activeJobText {
    color: #1C2640;
  }

  .activejob {
    color: #1C2640;
  }
}

.cardHeader {
  display: flex;
  align-items: center;
}

.companynameAndLocation {
  margin-left: .8rem;
}

.companyName {
  color: #080B2E;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 34px;
}

.companyLogo {
  border-radius: 10px;
}

.companyLocation {
  display: flex;
}

.location {
  font-weight: 500;
  color: #5F6981;
  font-size: 1.2em;
  padding-top: .2rem;
  margin-left: .2rem;
}

.centerVerticalHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breakLine {
  border: 1px solid #F8F9FB;
}

.activeJobContainer {
  background-color: #FFF4E6;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 1rem .8rem;
  color: #1C2640;
}

.activeJobMoreIcon {
  background-color: white;
  padding: .5rem .9rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeJobText {
  font-size: .87em;
  font-weight: 400;
}

.activejob {
  font-weight: 600;
  font-size: 1.62em;
  line-height: 35.98px;
}

.companyCareerPageSection {
  background-color: #6196FE;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .8rem .9rem;
  margin-top: 1.2rem;
  cursor: pointer;
}

.companyPageText {
  color: white;
  font-weight: 500;
  font-size: 1.12em;
  line-height: 20px;
  text-decoration: none !important;
}

.paginateContainer {
  margin-top: 3rem;
}

.viewAllJobsButton {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.viewAllJobsBtn {
  background-color: #6196FE;
  border-radius: 6px;
  color: white;
  padding: .8rem 1.2rem;
  border: 0;
}

.viewAllJobsBtn:hover {
  background-color: #508afd;
}

.viewAllJobsBtn:focus {
  outline: none;
}

.bannerSection {
  background-color: #FFFFFF;
  padding: 7rem 5rem;
}

.bannerContainer {
  background-color: #6196FE;
  border-radius: 5px;
  padding: 4rem;
}

.bannerTextAndButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bannerImgAndText {
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: 2rem;
}

.bannerPrimaryText {
  color: #FFFFFF;
  line-height: 61px;
  font-weight: 600;
  font-size: 2.75em;
}

.bannerSecondText {
  color: #FFFFFF;
  line-height: 24px;
  font-weight: 400;
  font-size: 1.15em;
  margin-top: 2rem;
}

.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.navigationBarMobile {
  display: none;
}

.emptyStateText {
  text-align: center;
  color: #7A91AB;
  font-size: 1.1em;
  font-weight: 400;
}

.footerSection {
  margin-top: 2rem;
}

.copyrightText {
  text-align: center;
  color: #7A91AB;
  font-size: 1em;
  font-weight: 400;
  line-height: 20px;
}

.footerLineSeperator {
  padding: 0rem 2rem;
}

.hamContainer {

  div {
    width: 2rem;
    height: .25rem;
    border-radius: 10px;
    transition: all .3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: #080B2E;
  }
}

.navContainer {
  display: block;
}

@media (max-width: 1220px) {

  .navigationButtons {
    margin-right: 5rem !important;
  }

  .navItems {
    margin-left: 5rem !important;
    gap: 1.5rem !important;
  }

  .bannerPrimaryText {
    color: #FFFFFF;
    line-height: 51px;
    font-weight: 600;
    font-size: 2.45em;
  }
  
  .bannerSecondText {
    color: #FFFFFF;
    line-height: 24px;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 1rem;
  }

  .bannerContainer {
    padding: 3rem;
  }
}

@media (max-width: 1140px) {
  
  .bannerPrimaryText {
    color: #FFFFFF;
    line-height: 41px;
    font-weight: 600;
    font-size: 2.15em;
  }
  
  .bannerSecondText {
    color: #FFFFFF;
    line-height: 24px;
    font-weight: 400;
    font-size: 1.05em;
    margin-top: .8rem;
  }

  .bannerContainer {
    padding: 3rem;
  }

  .navigationButtons {
    margin-right: 2rem !important;
  }

  .navItems {
    margin-left: 2rem !important;
    gap: 1rem !important;
  }

  .cardGrid {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 1070px) {

  .navigationButtons {
    margin-right: 2rem !important;
  }

  .navItems {
    margin-left: 2rem !important;
    gap: 1rem !important;
  }
}

@media (max-width: 1070px) {

  .notificationText {
    font-size: .8em;
  }

  .companyHiringText {
    font-size: 4em;
  }

  .topSectionContent {
    padding: 5.5rem 0;
  }
}

@media (max-width: 991px) {
  
  .navItems {
    display: block;
    margin-left: .5rem;
    margin-top: 1rem;
  }

  .InterviewerText {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navigationButtons {
    display: flex;
    flex-direction: column;
  }

  .cardGrid {
    grid-template-columns: auto;
  }

  .navItem {
    display: block !important;
    margin: .9rem 0rem !important;
    font-size: 1.3em !important;
    transition: none;
  }

  .navItem:hover {
    transform: scale(1);
  }

  .navItemDropdown {
    display: block !important;
    margin: .9rem 0rem !important;
    font-size: 1.3em !important;
  }

  .notificationText {
    font-size: .8em;
  }

  .companyHiringText {
    font-size: 3.5em;
  }

  .topSectionContent {
    padding: 4rem 0;
  }

  .heroText {
    font-size: 1em;
  }

  .jobCardsContainer {
    padding: 0rem 5rem;
  }

  .bannerPrimaryText {
    margin-top: 1rem;
    color: #FFFFFF;
    line-height: 41px;
    font-weight: 600;
    font-size: 2.15em;
    text-align: center;
  }
  
  .bannerSecondText {
    color: #FFFFFF;
    line-height: 24px;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 1rem;
    text-align: center;
  }

  .bannerContainer {
    padding: 4rem 3rem;
  }

  .bannerImgAndText {
    display: block;
  }

  .signupBannerBtnSection {
    justify-content: center;
    align-content: center;
  }
}

@media (max-width: 480px) {
  
  .navItems {
    display: block;
    margin-left: .5rem;
    margin-top: 1rem;
  }

  .cardGrid {
    grid-template-columns: auto;
  }

  .navItem {
    display: block !important;
    transition: none;
  }

  .navItem:hover {
    display: block !important;
    transform: scale(1);
  }

  .navItemDropdown {
    display: block !important;
  }

  .notificationText {
    font-size: .95em;
  }

  .companyHiringText {
    font-size: 2.2em;
    font-weight: 600;
  }

  .topSectionContent {
    padding: 1rem;
  }

  .heroText {
    font-size: 1.1em;
  }

  .center {
    display: block;
    text-align: center;
  }

  .heroText {
    width: 100%;
    text-align: center;
  }

  .searchboxContainer {
    width: 100%;
    margin: 1rem auto;
  }

  .jobCardsHeading {
    font-size: 2em;
    margin: 1rem .7rem;
  }

  .jobCardsText {
    width: 100%;
    text-align: center;
    padding: 0rem 1rem;
  }

  .jobCardsContainer {
    padding: 0rem 1rem;
  }

  .location {
    font-size: 1.1em;
  }

  .companyPageText {
    font-size: 1.07em;
  }

  .loginButton {
    font-size: 1.1em;
  }

  .signupButton {
    font-size: 1.1em;
  }

  .bannerSection {
    padding: 0rem;
  }

  .viewAllJobsBtn {
    padding: .8rem 2rem;
    font-size: 1.1em;
  }

  .jobCardsSection {
    padding-bottom: 3rem;
  }

  .footerLineSeperator {
    padding: 0rem;
  }

  .copyrightText {
    font-size: .8em;
    padding-bottom: 1rem;
  }

  .bannerContainer {
    padding: 4rem 2rem;
  }

  .signupBannerBtnSection {
    justify-content: center;
    align-content: center;
  }
}