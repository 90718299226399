$color-primary-light-red: #f77d60;
$color-primary-red: #fd6144;
$color-primary-light: #8daffe;
$color-primary: #6196fe;
$color-grey: #999999;
$color-text-grey: #717171;
$color-border-grey: #ddd;
$color-light-grey: #f2f2f2;
$color-white: #ffffff;
$color-black: #2e3239;
$color-background-blue: #CADBFD;
$color-background-red: #F6D2D8;
$color-background-grey: #EBEBEB;

$color-yellow: #fdeda3;
$color-yellow-darker: #f8cd49;
$color-blue: #3582fb;
$color-green: #66BB6A;
$color-green-light: #A5D6A7;

$spacing-xs: .375rem;
$spacing-sm: .75rem;
$spacing-md: 1.125rem;
$spacing-base: 1.5rem;
$spacing-lg: 1.875rem;
$spacing-xl: 2.250rem;
$spacing-xxl: 2.625rem;
$spacing-xxxl: 3rem;
$spacing-super-big: 3.5rem;

$font-size-sm: .25rem;
$font-size-sm-base: .4rem;
$font-size-base: .5rem;
$font-size-base-lg: .625rem;
$font-size-lg: .75rem;
$font-size-lg-xl: .875rem;
$font-size-xl: 1rem;
$font-size-xxl: 1.25rem;
$font-size-xxxl: 1.5rem;
$font-size-xxxxl: 2rem;
$font-size-super-big: 3rem;
$font-size-extra-big: 4.5rem;
$font-size-root: 100%;

$line-height-sm: 1.33;
$line-height-md: 1.5;
$line-height-lg: 1.87;

%navbar-btn {
  margin: $spacing-xs $spacing-sm 0 $spacing-sm;
  width: 135px;
}

.btn-primary-custom {
  color: $color-white;
  background-color: $color-primary;
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
  border-color: $color-primary;
}

.btn-primary-custom:hover {
  transition: background .2s;
  background-color: $color-primary-light;
  border-color: $color-primary;
}

.btn-primary-custom:focus,
.btn-primary-custom:active,
.btn-primary-custom:disabled {
  background-color: $color-primary-light !important;
  border-color: $color-primary !important;
  box-shadow: none;
}

.btn-primary-custom-gradient {
  color: $color-white;
  background-image: linear-gradient(to right, $color-primary-light, $color-primary);
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
}

.btn-default-custom {
  color: $color-black;
  background-color: #fff;
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
  border: 1px solid $color-border-grey;
}

.btn-warning-custom {
  color: $color-black;
  background-color: $color-yellow;
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
}

.btn-secondary-custom {
  color: $color-white;
  background-color: $color-black;
  padding-left: $spacing-xxl;
  padding-right: $spacing-xxl;
}

.btn-secondary-custom:hover,
.btn-secondary-custom:focus {
  transition: background .2s;
  background-color: $color-grey;
}

.containerWithCarousel {
  background: #ffffff !important;
  padding: 1.125rem 1.875rem !important;
}

// Small tablets and large smartphones (portrait view)
$screen-sm-min: 480px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin lessthansm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin lessthanmd {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lessthanlg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

.flex {
  display: flex;

  @include lessthanmd {
    width: 100%;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

/* recording app */
.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-30 {
  flex: 30;
}

.margin-top-lg {
  margin-top: $spacing-lg;
}

.margin-top {
  margin-top: $spacing-base;
}

.margin-bottom-lg {
  margin-bottom: $spacing-lg;
}

.margin-bottom {
  margin-bottom: $spacing-base;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

// ---- solve cube

.custom-voilet-button {
  background-color: #9150bc;
  border-radius: 30px;
  padding: .2em;
  color: white;
  border: 0px;
  padding: .2em .9em;
}

.bg-voilet {
  background-color: #9150bc;
}