.btn-primary-custom {
  color: #ffffff;
  background-color: #6196fe;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  border-color: #6196fe; }

.btn-primary-custom:hover {
  transition: background .2s;
  background-color: #8daffe;
  border-color: #6196fe; }

.btn-primary-custom:focus,
.btn-primary-custom:active,
.btn-primary-custom:disabled {
  background-color: #8daffe !important;
  border-color: #6196fe !important;
  box-shadow: none; }

.btn-primary-custom-gradient {
  color: #ffffff;
  background-image: linear-gradient(to right, #8daffe, #6196fe);
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-default-custom {
  color: #2e3239;
  background-color: #fff;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  border: 1px solid #ddd; }

.btn-warning-custom {
  color: #2e3239;
  background-color: #fdeda3;
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-secondary-custom {
  color: #ffffff;
  background-color: #2e3239;
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-secondary-custom:hover,
.btn-secondary-custom:focus {
  transition: background .2s;
  background-color: #999999; }

.containerWithCarousel {
  background: #ffffff !important;
  padding: 1.125rem 1.875rem !important; }

.flex {
  display: flex; }
  @media (max-width: 768px) {
    .flex {
      width: 100%; } }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-grow {
  flex-grow: 1; }

/* recording app */
.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-30 {
  flex: 30; }

.margin-top-lg {
  margin-top: 1.875rem; }

.margin-top {
  margin-top: 1.5rem; }

.margin-bottom-lg {
  margin-bottom: 1.875rem; }

.margin-bottom {
  margin-bottom: 1.5rem; }

.center-items {
  display: flex;
  justify-content: center;
  align-items: center; }

.custom-voilet-button {
  background-color: #9150bc;
  border-radius: 30px;
  padding: .2em;
  color: white;
  border: 0px;
  padding: .2em .9em; }

.bg-voilet {
  background-color: #9150bc; }

.apply-screen-div {
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;
  flex-direction: column; }
  .apply-screen-div__background {
    background: url("../assets/recording-background.jpg") no-repeat center center;
    background-size: cover;
    min-height: 100vh; }

.apply-screen {
  padding: 1.125rem 0px;
  width: 100%; }
  .apply-screen h1 {
    padding-top: 3.5rem;
    font-weight: 300; }
  .apply-screen h3,
  .apply-screen h4 {
    font-weight: 300; }
  .apply-screen__resumebtn {
    display: flex;
    flex-flow: column; }
  .apply-screen__error-p {
    font-size: 1rem;
    font-weight: 300; }
  .apply-screen--text-red {
    color: #fd6144; }
  .apply-screen__description {
    font-size: 1rem;
    line-height: 1.87;
    font-weight: 300;
    white-space: pre-line;
    max-height: 100px;
    overflow: hidden;
    position: relative; }
  .apply-screen__description-expand {
    max-height: 100%; }
  .apply-screen__description-expand:after {
    content: none !important; }
  .apply-screen__description-blur:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
    width: 100%;
    height: 4em; }
  .apply-screen__instructions {
    margin-top: 2.25rem;
    font-size: 1rem;
    line-height: 1.87;
    font-weight: 300;
    white-space: pre-line; }
  .apply-screen__readmoreButton {
    cursor: pointer;
    color: #f77d60; }
  .apply-screen__readmoreButton:hover {
    text-decoration: underline; }
  .apply-screen__readmoreButton:active, .apply-screen__readmoreButton:focus {
    outline: none; }
  .apply-screen__company-logo {
    margin-top: 1.5rem;
    margin-bottom: 1.875rem; }
  .apply-screen__company-logo img {
    max-width: 200px;
    max-height: 135px; }
  .apply-screen__company-name {
    margin-top: 1.875rem; }
  .apply-screen__apply-btn {
    margin-top: 1.125rem; }
    @media (min-width: 768px) {
      .apply-screen__apply-btn {
        float: right; } }
    @media (max-width: 768px) {
      .apply-screen__apply-btn {
        display: block;
        width: 100%; } }
  .apply-screen__consent-div {
    margin-top: 0.75rem;
    color: #999999;
    font-weight: 300; }
    @media (max-width: 768px) {
      .apply-screen__consent-div {
        display: block; } }
    .apply-screen__consent-div a {
      color: #6196fe; }
  .apply-screen__powered-by-div {
    margin-top: 1.125rem;
    padding-bottom: 1.125rem; }
    .apply-screen__powered-by-div p {
      font-weight: 300;
      font-style: italic;
      color: #999999;
      letter-spacing: 1.4px;
      margin-bottom: 0; }
    .apply-screen__powered-by-div img {
      width: 120px; }
  .apply-screen .react-tel-input {
    width: auto; }
    .apply-screen .react-tel-input .selected-flag,
    .apply-screen .react-tel-input .selected-flag:focus,
    .apply-screen .react-tel-input .selected-flag:active {
      outline: 0;
      background: transparent;
      border: 0;
      height: unset !important; }
    .apply-screen .react-tel-input .country-list {
      top: 40px; }
    .apply-screen .react-tel-input input[type="tel"] {
      height: 38px !important; }
    .apply-screen .react-tel-input .invalid-number {
      border-color: #dc3545 !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
      background-repeat: no-repeat !important;
      background-position: right calc(.375em + .1875rem) center !important;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important; }
  .apply-screen .form-control {
    background-color: #f2f2f2;
    padding: 0.5rem 1.125rem; }
  .apply-screen select.form-control {
    height: 42px;
    color: #717171;
    padding: 0 1.125rem; }
  .apply-screen .react-tel-input .form-control {
    background: #f2f2f2; }
  .apply-screen .form-control-with-value,
  .apply-screen .react-tel-input.form-control-with-value .form-control {
    background-color: #ffffff; }
  .apply-screen .control-label {
    font-size: 0.5rem;
    font-weight: 300;
    color: #999999;
    letter-spacing: 1.4px;
    text-transform: uppercase; }
  .apply-screen .btn {
    white-space: normal; }
  .apply-screen .has-error > .react-tel-input > .form-control {
    border-color: #a94442;
    background: #ffffff; }

* {
  padding: 0;
  margin: 0; }

.application-page {
  padding: 2rem 5rem 4rem 5rem;
  padding-bottom: 0 !important;
  background-color: #f8faff; }

.applicationFormContentContainer {
  padding: 0rem 5rem;
  padding-bottom: 2rem;
  background-color: #f8faff;
  margin-top: 0 !important;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 100%; }

.applicationFormContentContainer::-webkit-scrollbar {
  width: 0; }

.applicationFormContentContainerForMobile {
  padding: 1.4rem;
  padding-top: 0 !important; }

/* hero section CSS */
.hero-section {
  padding: 2.8rem;
  display: flex;
  flex-direction: column;
  background: #16182f;
  border-radius: 20px 20px 0px 0px; }

.hero-section-browser {
  padding: 2.8rem 1.9rem;
  background: #16182f;
  border-radius: 20px 20px 0px 0px;
  z-index: 22;
  position: sticky;
  top: 1rem; }

.companyLogo-companyName {
  display: flex;
  flex-direction: column; }

.company-logo > img {
  width: 80px;
  height: 80px;
  margin: .5rem 0rem; }

.company-section {
  display: flex;
  margin: .5rem 0rem; }

.company {
  color: #9ba4ba;
  margin-right: .3rem;
  font-size: 1em;
  font-weight: 400; }

.company-name {
  color: #ffffff;
  font-size: 1em;
  font-weight: 400; }

.role {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8em; }

.city-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: .3rem 0rem; }

.city-name {
  color: #cecccc;
  font-size: 1em;
  font-weight: 400;
  text-transform: capitalize; }

.btn-apply-now {
  background-color: #6196fe;
  color: #ffffff;
  border-radius: 8px;
  font-size: 1em;
  border: 0;
  padding: .6925rem 1.625rem; }

.btn-apply-now:focus {
  outline: none; }

.btn-apply-now:hover {
  background-color: #4978d6;
  transition: all .1s linear; }

.navigation-mobile {
  display: none; }

.outerParent {
  height: 100vh;
  overflow-y: hidden; }

.detail-section {
  padding: 2.3rem 2.3rem 1.5rem 2.3rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 9fr 4fr;
  box-shadow: 0px 3px 24px rgba(97, 150, 254, 0.1);
  height: 67vh; }

.firstPart {
  overflow-x: scroll; }

.secondPart {
  padding-top: 1rem; }

.experience-section {
  display: inline-flex;
  flex-direction: row;
  gap: 4rem; }

.exp-list {
  display: flex;
  flex-direction: column; }

.exp-value {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 32px;
  color: #16182f;
  text-transform: capitalize; }

.exp-item {
  font-weight: 400;
  font-size: 1rem;
  color: #7989b1; }

.role-section {
  margin-top: 2rem; }

.role-heading {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 32.52px;
  color: #16182f; }

.role-discription {
  padding: .9em;
  font-size: 1rem;
  line-height: 35px;
  font-weight: 400;
  color: #222e50; }

.applicationText {
  font-size: 1.4em;
  font-weight: 500;
  line-height: 32.52px;
  color: #16182f; }

.applicationForm-section {
  margin-top: 2rem; }

html {
  scroll-behavior: smooth; }

.applicationInputField {
  margin-top: 1.2rem; }

.applicationRequired {
  font-size: .9em;
  color: #7989b1;
  line-height: 21.68px;
  font-weight: 500; }

.main-section {
  border: 1px solid #e8ecf3;
  background-color: #ffffff;
  border-radius: 0px 0px 20px 20px; }

.brandPoweredBy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem; }

.poweredBy {
  color: #7a91ab;
  font-size: .775em;
  font-weight: 400;
  line-height: 18.79px; }

.form-value {
  font-size: 1.025em;
  font-weight: 400;
  line-height: 24.16px;
  color: #7989b1; }

.form-control {
  padding: .5rem 1rem !important;
  width: 100% !important;
  border-radius: 12px !important;
  border: 1px solid #7A91AB4D !important;
  height: 2.7rem !important; }

.form-control::placeholder {
  color: #9BA4BA; }

.selected-flag {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 0;
  padding-right: 1rem !important;
  margin-left: .4rem !important;
  border-right: 1px solid #7A91AB;
  background-color: #ffffff !important;
  width: 45px !important; }

.react-tel-input .flag-dropdown {
  background-color: #ffffff !important;
  border: 0 !important; }

.selected-flag:focus {
  outline: none; }

.flag-dropdown {
  align-items: center !important;
  display: inline-flex !important;
  border-right: 1px solid #7A91AB !important; }

.react-tel-input {
  width: 100% !important; }

.react-tel-input > input {
  padding-left: 3.7rem !important; }

.submit-btn-apply-now {
  background-color: #6196fe;
  color: #ffffff;
  border-radius: 8px;
  font-size: 1em;
  border-color: #3678fc;
  padding: .5925rem 2.25rem;
  margin-top: 1rem; }

.submit-btn-apply-now:focus {
  outline: none; }

.submit-btn-apply-now:hover {
  background-color: #4978d6; }

.applicationformApplySuccessContent {
  width: 31.25rem;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 5em !important;
  padding: 2.5rem 1.5rem;
  display: block;
  width: 72%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, 0.1); }

/*This container class is for the drag and drop component*/
.dragArea {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.dragMutedText {
  color: #7989b1;
  font-size: .75em;
  font-weight: 400;
  margin: 0;
  text-align: center; }

.dragText {
  color: #1c2640;
  font-size: 1.025em;
  font-weight: 400;
  margin: 0;
  text-align: center; }

.dragMutedText-mobile {
  color: #7989b1;
  font-size: .65em;
  font-weight: 400;
  margin-left: .3rem;
  text-align: center; }

.dragText-mobile {
  color: #1c2640;
  font-size: 1.025em;
  font-weight: 400;
  margin: 0;
  text-align: start;
  width: 100%; }

.container {
  padding: 0 !important;
  margin: 0 !important;
  width: 50%; }

.dragAreaMobile {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important; }

/* Styles for Error Screen */
.error_page {
  padding: 4rem 8rem;
  background-color: #f8faff; }

.error_heroSection {
  padding: .8rem 1.9rem;
  background: #16182f;
  border-radius: 20px 20px 0px 0px;
  z-index: 22; }

.expired-button {
  background-color: #a5adbe;
  color: #5a5a5a;
  border-radius: 8px;
  font-size: 1em;
  border: 0;
  padding: .7925rem 1.625rem; }

.expired-button:focus {
  outline: none; }

.errorContent {
  background-color: white;
  height: 480px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, 0.1); }

.errorContentText {
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.error-message1 {
  color: #a2aabd;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 34px; }

.error-message2 {
  color: #a2aabd;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 24px; }

.error-messageLink {
  color: blue;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer; }

.error-messageLink:hover {
  text-decoration: underline; }

.fileInputBox {
  cursor: pointer;
  width: 50%; }

.inputFileDiv {
  cursor: pointer; }

.chooseBtn {
  font-weight: 400;
  padding: .6rem 1rem;
  border: 0;
  background-color: #e4e4e4;
  border-radius: 8px; }

.chooseBtn:hover {
  color: white;
  background-color: blue; }

.chooseBtn:focus {
  outline: none; }

.selectedFile {
  font-weight: 200;
  margin-left: .5rem;
  color: #979797; }

.validselectedFile {
  margin-left: .5rem; }

::-webkit-file-upload-button {
  font-weight: 400;
  padding: .5rem 1rem;
  border: 0;
  background-color: #e4e4e4; }

.inputFileDiv:hover ::-webkit-file-upload-button {
  background-color: blue;
  color: white; }

.expiryModalContent {
  text-align: center;
  margin: 0rem 5rem;
  color: #a2aabd; }

.fixedOnBottom {
  position: sticky;
  bottom: -2rem;
  background-color: white;
  z-index: 14;
  padding: 1rem 0rem 2rem 0rem; }

/* media queries */
@media (min-width: 1200px) {
  .role-discription {
    margin-right: 2.5rem; } }

@media (max-width: 768px) {
  .outerParent {
    height: max-content;
    overflow-y: auto; }
  .detail-section {
    height: max-content; }
  .expiryModalContent {
    margin: 0rem 1rem;
    font-size: 1.05em; }
  .navigation-mobile {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2rem; }
  .role {
    font-size: 1.4em; }
  .company .company-name {
    font-size: .8375em; }
  .city-name {
    font-size: .9em;
    text-transform: capitalize; }
  .btn-apply-now {
    font-size: .9em; }
  .interviewer-logo {
    height: 48px;
    width: 48px; }
  .application-page {
    padding: 1.4rem; }
  .error_page {
    padding: 1.4rem; }
  .hero-section {
    padding: 2rem;
    margin-bottom: 0px;
    border-radius: 20px 20px 0px 0px; }
  .error_heroSection {
    padding: 2rem;
    margin-bottom: 0px;
    border-radius: 20px 20px 0px 0px; }
  .companyLogo-companyName {
    display: flex;
    flex-direction: row; }
  .company-logo > img {
    margin: .5rem 0rem; }
  .company {
    font-size: 1em; }
  .company-name {
    font-size: 1em; }
  .company-section {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .company-section > div {
    margin-left: .5rem; }
  .city-section {
    display: flex;
    flex-direction: column; }
  .btn-apply-now {
    width: 100%; }
  .role {
    margin-bottom: .5rem; }
  .city-name {
    width: 100%;
    margin-bottom: .5rem;
    text-align: start;
    text-transform: capitalize; }
  .main-section {
    border-bottom: 1px solid #e8ecf3;
    border-left: 1px solid #e8ecf3;
    border-right: 1px solid #e8ecf3;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #ffffff; }
  .detail-section {
    margin-top: 0px;
    padding: .7rem;
    display: block; }
  .experience-section {
    display: block; }
  .exp-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .exp-value {
    font-size: .9em;
    color: #16182f;
    text-transform: capitalize !important; }
  .exp-item {
    font-size: .775em; }
  .role-section {
    margin-top: 1rem; }
  .role-heading {
    font-size: 1.15em;
    font-weight: 500;
    line-height: 20px;
    color: #16182f; }
  .role-discription {
    font-size: .775em;
    line-height: 26px;
    font-weight: 400;
    color: #222e50; }
  .applicationText {
    font-size: 1.15em;
    font-weight: 500;
    line-height: 20px;
    color: #16182f; }
  .applicationRequired {
    margin-top: .4rem;
    font-size: .775em;
    color: #7989b1;
    line-height: 14px;
    font-weight: 500; }
  .secondPart {
    display: none; }
  .form-control {
    padding: 1rem !important;
    width: 100% !important;
    border-radius: 12px !important;
    border: 1px solid #7989b1 !important; }
  .poweredBy {
    color: #7a91ab;
    font-size: .65em;
    font-weight: 400;
    line-height: 12px; }
  .submit-btn-apply-now {
    background-color: #6196fe;
    color: #ffffff;
    border-radius: 8px;
    font-size: .9em;
    border-color: #3678fc;
    width: 100%;
    padding: .5925rem 1.625rem;
    margin-top: .5rem; }
  .container {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%; }
  .expired-button {
    width: 100%; }
  .errorContent {
    padding: 1rem;
    height: auto; }
  .errorContentText {
    justify-content: flex-start;
    margin-top: 2rem; }
  .error-message2 {
    text-align: center;
    margin-top: .5rem; } }
