* {
  padding: 0;
  margin: 0;
}

.termsAndCondtionSection {
  padding: 2.8rem;
  background-color: #f8f9fb;
}

.mainSection {
  width: 500px;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 2rem !important;
  padding: 2.5rem 1.5rem;
  display: block;
  width: 75%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 1px 24px rgba(97, 150, 254, .1);
}

.mainSection::-webkit-scrollbar-track {
  padding: 2px 0;
}

.mainSection::-webkit-scrollbar {
  width: 10px;
}

.mainSection::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px #cdd3e1;
  background-color: #cdd3e1;
}

.terms {
  margin: 2rem 2rem;
}

.termsPoints {
  color: #16182f;
  font-size: 1rem;
  font-weight: 600;
  line-height: 32px;
}

.termsHeading {
  margin-bottom: 0 !important;
  font-weight: 600;
  font-size: 1rem;
  color: #16182f;
  margin-top: .4rem;
}

.rightsUnderDdpr {
  margin-left: .4rem;
  color: #16182f;
  font-weight: 500;
  font-size: .875rem;
}

.termsDescription {
  color: #7989b1;
  font-size: .875rem;
  font-weight: 500;
  line-height: 30px;
}

.companyName {
  text-align: center;
  color: #16182f;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 26px;
}

.termsConditionHeading {
  text-align: center;
  color: #16182f;
  font-weight: 700;
  line-height: 41px;
  font-size: 1.875rem;
}

.button {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.btn {
  color: white;
  background-color: #6196fe;
  font-size: 16px;
  padding: .6rem 1.5rem;
  border-radius: 10px;
  border: 0;
}

.btn:hover {
  background-color: #3275fa;
  transition: all .2s linear;
}

.btn:focus {
  outline: none;
}

// mobile screens

.termsAndCondtionSectionForMobile {
  background-color: #f8f9fb;
  padding: 1rem;
}

.mainSectionForMobile {
  overflow-x: hidden;
  background-color: #f8f9fb;
  padding: .5rem;
}

.companyNameForMobile {
  display: none;
}

.termsConditionHeadingForMobile {
  line-height: 41px;
  font-weight: 700;
  font-size: 1.874rem;
  margin-top: 1.3rem;
  margin-bottom: .2rem;
}

.termsForMobile {
  padding: .5rem;
}

.buttonForMobile {
  height: 12vh;
  width: 100%;
  background-color: #ffffff;
  border-color: #ffffff;
  bottom: 0px !important;
  position: sticky !important;
}

.btnForMobile {
  margin-top: 1rem;	
  width: 100%;
  color: white;
  background-color: #6196fe;
  font-size: 16px;
  padding: .7rem 1.5rem;
  border-radius: 10px;
  border-color: #6196fe;
}

.btn:hover {
  background-color: #3275fa;
}
