@import "./base";


// .candidateDetailContainer {
//   display: flex;
//   flex-direction: row;
//   // padding-left: .8em;
//   // padding-right: 1em;
// }

// .candidateDetailPage {
//   background-color: #FFFFFF;
//   border-top-left-radius: 3em;
//   border-bottom-right-radius: 3em;
//   flex: 1;
//   padding: 4.5em 2.2em 2.2em 2.2em;

// }

// .backButton {
//   display: flex;
//   background: transparent;
//   border: 0px;
//   gap: .6em;
//   justify-content: center;
//   align-items: baseline;
// }

// %companysection {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .companysection {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .subheaderSection {
//   margin-bottom: 1.5em
// }



// .companyInfosection {
//   gap: .5em;
  
//   @extend %companysection;
// }

// .interviewName {
//   font-size: 1.6em;
//   color: #16182F;
//   margin-bottom: 0;
// }

// .companyName {
//   font-size: 1.1em;
//   color: #16182F;
// }

// .downloadButtonContainer {
//   display: none;
// }

// .mobileBlock {
//   display: none;
// }

// .downLoadButton {
//   background: #6196FE;
//   color: #FFFFFF;
//   border-radius: 10px;
//   justify-content: center;
//   align-items: center;
//   font-weight: 100;
//   padding: .7em 1em;
//   border: 0px;
// }

// .interviewNameSection {
//   display: flex;
//   justify-content: space-between;
// }


// .dummyReportImg {
//   background-size: contain;
//   margin-left: 3%;
//   margin-right: 3%;
//   width: 92%;
//   object-fit: cover;
// }

// @media screen and (min-width: 1441px) {

//   .backText {
//     font-size: 1.2em;
//     color: #16182F;
//     margin-bottom: 0;
//     margin-bottom: 1em;
//   }

//   .interviewName {
//     font-size: 1.7em;
//   }

// }

// @media screen and (max-width: 750px) {

//   .appDrawerCont {
//     display: none;
//   }

//   .candidateDetailPage {
//     padding: 0;
//     margin: 0;
//     border-radius: 0%;
//     // background-color: #FFFFFF;
//     background: #F8FAFF;
//     backdrop-filter: blur(13px);
//   }
  
//   .headerContainer {
//     padding: 1rem;
//     background-color: #FFFFFF !important;
//   }

//   .mobileBlock {
//     display: block;
//   }

//   .horizontalLine {
//     display: none;
//   }

// }


// Desktop version
.candidateDetailContainer {
  display: flex;
  flex-direction: row;
  padding: 0 1.125rem 1.125rem 1.1rem;
}

.appDrawerConatiner {
  // flex-basis: 10%;
}

.candidateInterviewSection {
  flex-basis: 100%;
  padding-top: 4rem;
  background: #FFFFFF;
  border-top-left-radius: 4em;
  border-bottom-left-radius: 4em;
  padding-bottom: 5em;
}

.basicInfoBar {
  display: grid;
  grid-template-columns: 1.5fr 2.7fr 11fr;
  padding: 2em;
  border-radius: 2em;
  border: 1px solid;
  background: linear-gradient(95.96deg, rgba(234, 240, 252, .3) .24%, #e3ecff 100%);
  border: 1px solid #d3e2ff;
  margin: 0 3em;
}

.overAllScoreConatiner {
  flex-basis: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff3ee;
  padding: 1rem 0;
  cursor: pointer;
  border-radius: .8em;
}

.basicInfoContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 4fr 4fr;
  margin-left: 2em;
  gap: 5em;
  align-items: flex-start;
}

.appliedOnSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  
}

.appliedOnSection > span:nth-child(2) {
  margin-right: 1em;
}

.overAllScore {
  text-align: center;
  color: #1c2640;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
}

.overallLabel {
  text-align: center;
  color: #5f6981;
  font-size: .88em;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 0;
}

.jobDetails {
  font-size: 1em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: .2em;
  align-self: baseline;
  flex-direction: column;
}

.jobDetails > span:first-child {
  color: #6196FE;
  font-size: 1.1em;
}

.jobDetails > span:nth-child(2) {
  font-size: 1em;
}

.jobDetails > span:nth-child(2) {
  font-size: .9em;
}


.downloadReportButton {
  background: #6196FE;
  color: #FFFFFF;
  padding: 1.5rem;
  font-size: 1em;
  letter-spacing: -.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 10px;
  max-height: 1rem;
  min-height: 1rem;
  font-weight: 300;
  letter-spacing: .01em;
  font-weight: 400;
}


.greyLabel {
  line-height: 22px;
  color: #9ba4ba;
  font-weight: 500;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: .5rem;
  gap: .5rem;
}


.candidateScoreContainer {
  width: 100%;
  height: 29%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.bannerContainer {
  width: 100%;
  height: 380px;
  padding: 0 4em;
}

.learnMoreButton {
  background: #6196FE;
  color: #FFFFFF;
  padding: 1.5rem;
  font-size: 1em;
  letter-spacing: -.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  max-height: 1rem;
  min-height: 1rem;
  font-weight: 300;
  letter-spacing: .01em;
  font-weight: 400;
  border-radius: 2em;
  position: absolute;
  top: 73%;
  left: 72%;
}

.centerElement {
  display: flex;
  align-items: center;
  height: 100%;
}

.centerElementEnd {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
}