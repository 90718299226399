// .root {
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   display: flex;
//   margin-top: 2.1875rem;
// }

// .questionContainer {
//   width: 33vw;
//   max-height: 100%;
//   display: block;
// }

// .videoContainer {
//   width: 25.5vw;
//   // display: none;
//   margin-left: auto;
//   margin-right: auto;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   display: block !important;
//   margin-top: -1.25rem;
// }

// .transScriptContainer {
//   width: 24.5vw;
//   margin-right: 3vw;
//   display: block;
//   height: 100%;
//   object-fit: contain;
// }

// .questionHeading {
//   margin-left: 3.125rem;
//   font-style: normal;
//   font-weight: 550 !important;
//   font-size: 1.625rem;
//   line-height: 2.25rem;
//   display: flex;
//   align-items: center;
//   // scss-lint:disable BorderZero
//   letter-spacing: -.03em;
// }

// .transcriptHeading {
//   white-space: nowrap;
//   margin-left: 0;
//   margin-top: auto;
//   margin-bottom: auto;  
//   font-style: normal;
//   font-weight: 550 !important;
//   font-size: 1.625rem;
//   line-height: 2.25rem;
//   display: flex;
//   align-items: center;
//   letter-spacing: -.03em;
  
// }

// .transcriptToggleContainer {
//   display: flex;
//   width: 96%;
//   justify-content: space-between;
//   align-items: center;
// }

// .questionListContainer {
//   margin-left: 3.125rem;
//   height: auto;
//   display: flex;
//   flex-direction: column;
//   width: 96%;
//   height: 32vh;
//   // overflow-x: scroll;
//   // overflow-y: scroll;
//   overflow: hidden auto;
// }

// .questionList {
//   list-style-type: none;
//   display: flex;
//   float: left;
//   // align-items: center;
//   margin: 0 auto;
//   justify-content: center;
//   margin-top: 1.625rem;
// }

// .questionContainer {
//   justify-content: center;
//   list-style-type: none;
//   height: 4rem;
// }

// .numberContainer {
//   height: 52px;
//   width: 36px;
//   margin-left: auto;
//   margin-right: auto;
//   text-align: center;
// }

// .numberSubContainer {
//   width: 35px;
//   height: 35px;
//   border-radius: 50%;
//   justify-content: center;
//   // opacity: 0.1;
//   // background-color: #7989B1;
//   background-color: #E6E9F1;
//   align-items: center;
// }

// .questionNumber {
//   width: 10px;
//   height: 25px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 25px;
//   align-items: center;
//   letter-spacing: -.03em;
//   color: #7989B1;
//   // color: black;
//   // &:hover {
//   //   color: #6196FE;
//   // }
//   margin-left: auto;
//   margin-right: auto;
//   text-align: center;
//   justify-content: center;
//   margin-top: .3125rem;
//   margin-bottom: auto;
// }

// .activeQuestionNumber {
//   width: 10px;
//   height: 25px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 25px;
//   align-items: center;
//   letter-spacing: -.03em;
//   color: #6196FE;
//   margin-left: auto;
//   margin-right: auto;
//   text-align: center;
//   justify-content: center;
//   margin-top: .3125rem;
//   margin-bottom: auto;
// }

// .questionList:hover .questionNumber {color: #6196FE;}


// .questionText {
//   max-width: 27.16vw;
//   width: min-width;
//   height: 24px;
//   font-style: normal;
//   font-weight: 490;
//   font-size: 1rem;
//   line-height: 1.5rem;
//   letter-spacing: -.03em;
//   color: #7989B1;
//   flex: none;
//   order: 0;
//   flex-grow: 1;
//   margin-left: .8125rem;
//   margin-top: auto;
//   margin-bottom: auto;
//   cursor: pointer;

//   &:hover {
//     font-weight: 600;
//     color: #1C2640;
//   }
// }

// .activeQuestionText {
//   // width: 502px;
//   // width: 100%;
//   max-width: 27.16vw;
//   height: 24px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: -.03em;
//   color: #1C2640;
//   flex: none;
//   order: 0;
//   flex-grow: 1;
//   margin-left: 13px;
//   // justify-content: center;
//   // align-items: center;
//   margin-top: auto;
//   margin-bottom: auto;
// }

// .rectangle {
//   width: .125rem;
//   height: .3856rem;
//   border-radius: 3px;
//   flex: none;
//   list-style-type: none;
//   background: linear-gradient(180deg, #BED4FF 0%, #FFFFFF 100%);
//   margin-top: 4.5rem;
//   margin-left: 15px;
// }

// .videoStyle {
//   max-width: 22rem;
//   max-height: 28.75rem;
//   // width: 352px;
//   width: 19.04vw;
//   // height: 460px;
//   height: 46vh;
//   border-radius: 35px;
//   margin-left: auto;
//   margin-right: auto;
//   align-items: center;
//   object-fit: fill;
//   pointer-events: none;
// }

// .playButton {
//   width: 4.2rem;
//   height: 3.75rem;
//   background-color: #6196FE;
//   outline: #6196FE;
//   border-radius: 50%;
//   border: 0;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   top: 0;
//   left: 0;

//   &:focus,
//   &:hover,
//   &:visited,
//   &:link,
//   &:active {
//     outline: #6196FE;
//   }
// }

// .progressBarContainer {
//   width: 96%;
//   display: flex !important;
//   flex-direction: row;
//   float: left;
//   margin-left: .9375rem;
//   margin-top: .9375rem;
//   object-fit: contain;
//   overflow-x: auto;
// }

// .progressBarContainer::-webkit-scrollbar {
//   width: 1px;
//   height: 1px;
//   display: none;
// }

// .progressBarContainer::-webkit-scrollbar-track {
//   display: none;
// }

// .progressBarContainer::-webkit-scrollbar-thumb {
//   display: none;
// }


// .progressBar {
//   width: 2vw;
//   height: 4px;
//   background: #7A91AB;
//   opacity: .6;
//   border-radius: 3px;
//   flex: none;
//   order: 1;
//   flex-grow: 1;
//   margin-left: .5rem;
//   justify-content: center;
//   align-items: center !important;
//   margin-bottom: auto !important;
//   margin-top: 5px;
// }

// .progressBarInactive {
//   width: 2vw;
//   height: 4px;
//   background: #7A91AB;
//   opacity: .6;
//   border-radius: 3px;
//   flex: none;
//   order: 1;
//   flex-grow: 1;
//   margin-left: .5rem;
//   justify-content: center;
//   align-items: center !important;
//   margin-bottom: auto !important;
//   margin-top: 5px;
// }

// .progressBar::-moz-progress-bar { 
//   background: #FE5819;
//   border-radius: 3px;
// }

// .progressBar::-webkit-progress-value { 
//   background: #FE5819;
//   border-radius: 3px;
// }


// .playButtonMainCont {
//   display: flex;
//   width: 96%;
//   // justify-content: ;
//   float: left;
//   align-items: center;
//   height: 3.75rem;
//   margin-top: 16px;
// }

// .transcriptSubContainer { 
//   width: 96%;
//   margin-top: 1.25rem;
//   margin-top: 16%;
//   height: 32vh;
//   overflow-y: auto;
// }

// .transcriptQuestion {
//   font-style: normal;
//   font-weight: 550;
//   font-size: 1.5rem;
//   line-height: 2.0625rem;
//   color: #1C2640;
//   flex: none;
//   order: 0;
//   align-self: stretch;
//   flex-grow: 0;
//   margin-top: .625rem;
// }

// .transcriptText {
//   font-weight: 500;
//   font-size: 1.125rem;
//   line-height: 1.625rem;
//   letter-spacing: -.03em;
//   color: #24304D;
//   order: 0;
//   align-self: stretch;
//   flex: none;
//   flex-grow: 0;
//   margin-top: 20px;
// }

// .noneDisplay {
//   display: none;
// }

// video::-webkit-media-controls-play-button {
//   display: none;
// }

// video::-webkit-media-controls-timeline {
//   display: none;
// }

// video::-webkit-media-controls-current-time-display {
//   display: none;
// }

// video::-webkit-media-controls-mute-button {
//   display: none;
// }

// video::-webkit-media-controls-volume-slider {
//   display: none;
// }

// video::-webkit-media-controls-fullscreen-button {
//   pointer-events: all !important;
//   z-index: 10000 !important;
// }

// video::-webkit-media-controls-time-remaining-display {
//   display: none;
// }


// @media screen and (max-width: 750px) {

//   .root {
//     width: 100%;
//     margin-left: auto;
//     margin-right: auto;
//     display: block;
//     margin-top: 2.1875rem;
//   }

//   .questionHeading {
//     margin-left: auto;
//     margin-right: auto;
//     text-align: center;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 14px;
//     letter-spacing: -.03em;
//     color: #5F6989;
//     margin-top: -20px;
//   }
  
//   .questionText {
//     display: none
//   }

//   .activeQuestionText {
//     display: none;
//   }

//   .activeQuestionText {
//     // width: 502px;
//     // width: 100%;
//     max-width: 27.16vw;
//     height: 24px;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 16px;
//     line-height: 24px;
//     letter-spacing: -.03em;
//     color: #1C2640;
//     flex: none;
//     order: 0;
//     flex-grow: 1;
//     margin-left: 13px;
//     // justify-content: center;
//     // align-items: center;
//     margin-top: auto;
//     margin-bottom: auto;
//     margin-top: -10px;
//   }

//   .questionListContainer {
//     margin-left: auto;
//     margin-right: auto;
//     text-align: center;
//     height: auto;
//     flex-direction: row;
//     width: 100%;
//     overflow: hidden auto;
//     margin-top: -10px;
//   }

//   .questionNumber {
//     display: none;
//   }

//   .videoContainer {
//     margin-top: 5vh;
//     width: 100%;
//   }

//   .videoStyle {
//     width: 98vw;
//     height: 28.75rem;
//     margin-left: 12px;
//     margin-right: 12px;
//     border-radius: 25px;
//   }

//   .numberSubContainer {
//     width: 5px;
//     height: 5px;
//     border-radius: 50%;
//     background-color: #9BA4BA;
//   }

//   .activeNumberSubContainer {
//     width: 30px;
//     height: 30px;
//     border-radius: 50%;
//     background-color: #6196FE;
//     top: 0;
//     margin-top: -12px;
//   }

//   .activeQuestionNumber {
//     left: 40%;
//     right: 40%;
//     top: 26.67%;
//     bottom: 23.33%;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 15px;
//     display: flex;
//     align-items: center;
//     letter-spacing: -.03em;
//     color: #F8F9FB;
//   }

//   .toggleButton {
//     display: none;
//   }
  
//   .transcriptHeading {
//     height: 2.25rem;
//     font-style: normal;
//     font-weight: 700;
//     font-size: 1.625rem;
//     line-height: 2.25rem;
//     margin-left: 16px;
//   }

//   .playButtonMainCont {
//     display: flex;
//     width: 100%;
//     float: left;
//     align-items: center;
//     height: 3.75rem;
//     margin-top: 16px;
//     margin-left: 1rem;
//   }


//   .playIcon {
//     width: 12px;
//     height: 15px;
//   }

//   .progressBarContainer {
//     width: 60%;
//     overflow-x: auto;
//   }

//   .transScriptContainer {
//     width: 100%;
//   }

//   .transcriptSubContainer {
//     width: 100%;
//     margin-left: 1rem;
//     max-height: 50vh;
//     height: 100%;
//     overflow-y: unset;

//   }
  
//   .transcriptQuestion {
//     height: 4.125rem;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 24px;
//     line-height: 33px;
//     color: #1C2640;
//     order: 0;
//     align-self: stretch;
//   }

//   .transcriptText {
//     width: 343px;
//     height: 3.25rem;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 26px;
//     letter-spacing: -.03em;
//     color: #24304D;
//     flex: none;
//     order: 0;
//     align-self: stretch;
//     flex-grow: 0;
//   }

//   .rectangle {
//     display: none;
//   }
  
// }


// @media screen and (min-width: 751px) {

//   .numberContainer {
//     width: 35px;
//     height: 35px;
//     border-radius: 50%;
//     justify-content: center;
//     // opacity: 0.1;
//     // background-color: #7989B1;
//     background-color: #E6E9F1;
//     align-items: center;
//   }

//   .activeNumberSubContainer {
//     width: 35px;
//     height: 35px;
//     border-radius: 50%;
//     background-color: #E6E9F1;
//     top: 0;
//     // margin-top: -12px;
//   }

//   .playButton {
//     width: 4.2rem;
//     height: 3.75rem;
//   }
// }



.videoSection {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 2.3fr minmax(0, 3fr) 0%;
  margin-bottom: 2%;
  height: 100%;
  max-height: 500px;
  background: #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  max-height: 430px;
  overflow-y: hidden;
}

.video_questions {
  background: #ffffff;
  border: 1px solid #e8ecf3;
  box-sizing: border-box;
  border-radius: 20px;
  overflow-y: scroll;
  // height: 85%;
  height: 100%;
  margin-right: 7%;
  margin-bottom: 32%;
  padding: 2rem .8rem;
}




.circle {
  position: relative;
  display: flex;
  border-radius: 100%;
  width: 3.125rem;
  // line-height: 3.125rem;
  text-align: center;
  background-color: #E6E9F1;
  z-index: 2;
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  font-weight: bold;
  cursor: pointer;
  
  a {
    color: gray;
    text-decoration: none;
  }
}

.circle:first-child {margin-top: 0;}

.circle:before {
  position: absolute; 
  border: 1px solid #999; 
  width: 0; 
  height: 25px; 
  display: block; 
  content: ""; 
  left: 50%; 
  z-index: 1; 
  top: -26px; 
  margin-left: -1px;
  border-image: linear-gradient(360deg, rgba(97, 150, 254,0), rgba(97, 150, 254,1)) 1;
}

.questionContainer:first-child .circle::before {
  display: none;
}

.question {
  color: #7989B1;
  align-self: center;
  font-weight: 400;
  align-self: baseline;
  cursor: pointer;
  font-size: 1rem;

}


.circleActive {
  color: #EfF5FF !important;
  background-color: #6196FE !important;
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 2.5rem;
  max-height: 2.5rem;
}


.questionContainer {
  display: flex;
  width: 95%;
  margin: .5rem auto;
  height: 70px;
  align-items: baseline;
}

.questionActive {
  font-weight: 600;
  font-size: 1rem;
  color: #1C2640;
}

.videoContent {
  width: 100%;
}

.video_transcript {
  overflow-y: scroll;
  overflow-y: scroll;
  // height: 80%;
  height: 70%;
  margin-top: 18px
}


.questionHeading {
  font-size: 1.4em;
  display: inline-block;
  font-weight: bold;
  margin-top: 1em;
}

.videoContainer {
  width: 300px;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    border-radius: 20px;
    border: 0px;
  }
}


.transcript_parent {
  padding: 0;
  padding: 15px;
  background: #ffffff;
  box-sizing: border-box;
  margin-right: 1%;
  margin-left: 1%;
}




.candidateDetailPlayButton {
  background: #6196FE;
  border-radius: 50%;
  color: white;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 0px;
}

.candidateDetailPlayButton:hover {
  box-shadow: 0 0 5px #6196FE; 
  transition: .2s ease-in-out;
  border: 0px;
}

.candidateDetailPlayButton:disabled {
  box-shadow: 0; 
  cursor: not-allowed;
  border: 0px;
}

.playerControlContainer {
  display: flex;
  flex-direction: row;
}

.progressBar {
  background: #E6E1E4;
  height: 4px;
  width: 28px;
  border-radius: 3px;
  cursor: pointer;
  color: #FE5819;
}

.progressBar::-moz-progress-bar { 
  background: #FE5819;
  border-radius: 3px;

}


.candidateDetailPlayButton {
  background: #6196FE;
  border-radius: 50%;
  color: white;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.candidateDetailPlayButton:hover {
  box-shadow: 0 0 5px #6196FE; 
  transition: .2s ease-in-out;
}

.candidateDetailPlayButton:disabled {
  box-shadow: 0; 
  cursor: not-allowed;
}

.playerControlContainer {
  display: flex;
  flex-direction: row;
}

.progressBar {
  background: #E6E1E4;
  height: 4px;
  width: 28px;
  border-radius: 3px;
  cursor: pointer;
  color: #FE5819;
}

.progressBar::-moz-progress-bar { 
  background: #FE5819;
  border-radius: 3px;

}


.progressBar::-webkit-progress-value { 
  background: #FE5819;
  border-radius: 3px;
}

.progress::-moz-progress-bar {
  background: #FE5819;
  border-radius: 3px;
}

.progressBarContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
  overflow-x: auto;
}

.questionActive {
  font-weight: 600;
  font-size: 1rem;
  color: #1C2640;
}

.videoTranscriptHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;

  span {
    font-size: 1.2em;
    display: inline-block;
    font-weight: bold;
  }
}

.transcriptQuestion {
  font-size: 1.06em;
  line-height: 24px;
  letter-spacing: -.03em;
  color: #7989b1;
}

.transcriptAnswer {
  font-size: 100;
  color: #5F6981;
}

.mobileQuestions {
  display: none;
}

.mobileQuestionText {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.2em; 
  padding: 0 1em; 
}

.activeLink {
  display: block;
  color: #F8F9FB !important;
}

.responseRoot {
  border: 1px solid #e8ecf3;
  height: 32rem;
  margin: 3.2em;
  padding: 3em;
  border-radius: 2em;
}

// @media screen and (max-width: 750px) {

//   .responseRoot {
//     background-color: #FFFFFF;
//   }

//   .questionHeading {
//     font-weight: 500;
//     font-size: .875rem;
//     line-height: .875rem;
//     align-items: center;
//     letter-spacing: -.03em;
//     color: #5F6981;
//     margin-left: auto;
//     margin-right: auto;
//     text-align: center;
//     display: block;
//     padding-top: 1rem;
//   }
  
//   .videoSection {
//     display: contents;
//     grid-auto-flow: row;
//   }

//   .switchContainer {
//     display: none;
//   }

//   .question {
//     display: none;
//   }

//   .video_questions {
//     display: flex;
//     margin-bottom: 0;
//     overflow-x: auto;
//     margin-left: auto;
//     margin-right: auto;
//     text-align: center;
//     scrollbar-width: thin;
//     box-sizing: border-box;
//     border-radius: 20px;
//     overflow-y: scroll;
//     height: 100%;
//     max-width: 90%;
//     background: #FFFFFF;
//     // backdrop-filter: blur(13px);
//   }

//   .video_questions::-webkit-scrollbar {
//     width: 10px;
//   }

//   .questionContainer {
//     width: 0;
//     height: 2.5rem;
//   }

//   .circle:before {
//     display: none;
//   }

//   .mobileQuestions {
//     display: block;
//     overflow-x: auto;
//   }

//   .videoContainer {
//     padding: 0rem 2.5rem;
//   }

//   .circle {
//     min-width: .6rem;
//     min-height: .6rem;
//     max-width: .6rem;
//     max-height: .6rem;
//     background-color: #9BA4BA;
//   }

//   .circleActive {
//     min-width: 1.4rem;
//     min-height: 1.4rem;
//     max-width: 1.4rem;
//     max-height: 1.4rem;
//     color: #EfF5FF !important;
//     background-color: #6196FE !important;
//   }

//   .questionContainer {
//     align-items: center;
    
//   }

//   .activeLink {
//     display: block;
//     color: #F8F9FB !important;
//     margin-top: .3rem;
//   }

//   .inActiveLink {
//     display: none;
//   }

//   .playerControlContainer {
//     width: 80%;
//   }

//   .progressBarContainer {
//     width: 18rem;
//   }

//   .video_transcript {
//     margin-left: 10%;
//   }

//   .transcript_child {
//     margin-left: 10%;
//     margin-top: 1rem;
//   }
// }


.mobileQuestionHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0em;

  p {
    color: #5F6981;
    margin: auto;
    font-size: 1.2em;
  }
}

.mobileButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
  overflow-y: hidden;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  height: max-content;
  padding: 1em 0;

  .inActiveButton {
    padding: .2em;
    border-radius: 50%;
    background-color: #6196FE;
    border-color: #6196FE;

    span {
      display: none; 
    }
  }

  .activeButton {
    padding: 1em 1.5em;
    background: #6196FE;
    color: #FFFFFF;
    border: 0px;
    border-radius: 50%;
    border-color: #6196FE;

    span {
      display: block; 
    }
  }
}

.questionTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #16182F;
    font-size: 1.5em;
  }
}

.videoPlayerContainer {
  width: 100%;
  padding: 1em;
  border-radius: 2em;

  video {
    width: 100%;
    border-radius: 2em;
  }
}

.videoTranscriptHeading {
  display: flex;
  align-items: center;
  padding: 0em 1em;
  flex-direction: column;

  p {
    font-size: 1.5em;
  }
}

.videoTranscriptHeadingDesktop {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1em;
  
  span {
    font-size: 1.4em;
  }

  div:first-child {
    align-self: flex-end;
  }
}

.mobileViewContainer {
  margin-top: 1em;
  background-color: #FFFFFF;
}