.mobileViewHeaderActionsSection {
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  background: #ffffff;
  margin-bottom: 1em;
}

.candidateImage {
  width: 3em;
  height: 3em;
  border-radius: 50%;
}

.companyDetail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.jobCompanyInfo {
  padding: 1em 2em;
  background-color: #FFFFFF;
}

.downloadReportButton {
  background: none;
  border: 0px;

}

.downloadReportButton:focus,

.downloadReportButton:active {
  outline: none;
}

.downloadReportButton {
  animation: pulse .5s;

}

.backSection {
  background-color: transparent;
  border: 0px;
}

@keyframes pulse {
  
}

.bannerContainer {

}


.learnMoreButton {
  background: #6196FE;
  color: #FFFFFF;
  padding: 1.5rem;
  font-size: 1em;
  letter-spacing: -.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 10px;
  max-height: 1rem;
  min-height: 1rem;
  font-weight: 300;
  letter-spacing: .01em;
  font-weight: 400;
  position: absolute;
  top: 88%;
  left: 35%;
}
