@import "./base";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: $color-light-grey;
  min-height: calc(100vh - 56px);
}

.button {
  padding: $spacing-sm $spacing-xl;
  margin: 0 $spacing-sm;
}

.actionBar {
  margin-top: $spacing-xl;
}

.card {
  background-color: $color-white;
  padding: $spacing-xxxl 120px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.icon {
  height: 250px;
  margin-bottom: $spacing-xxxl;
}
