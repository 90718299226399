.priceButton {
  background: #6196FE;
  border: 0px;
  border-radius: 10px;
  color: #FFFFFF;
  padding: .5em 1.2em;
  margin-top: .5em;
  position: absolute;
  top: 67%;
  left: 39%;
}