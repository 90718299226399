@import "_base.scss";

// .candidateDetailsContainer {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   width: 100%;
// }

// .header {
//   background: $color-white;
//   padding: $spacing-md;
//   border-bottom: 1px solid $color-border-grey;
// }

// .content {
//   margin: $spacing-lg;
// }

// .error {
//   margin-top: $spacing-xxl;
//   line-height: 2;
// }

// .insightsiframe {
//   border: 0;
//   width: 100%;
//   min-height: 90vh;
//   height: 100%;

//   @include md {
//     width: 75%;
//     height: 1650px;
//   }
// }

// .itemPanel {
//   border: 1px solid $color-border-grey;
//   border-radius: 5px;
//   padding: $spacing-sm;
//   background: $color-white;
//   margin: 0 $spacing-md $spacing-lg $spacing-lg;
//   flex: 1;
//   display: flex;
//   text-align: center;
//   min-height: 110px;
//   justify-content: center;
//   align-items: center;
// }

// .smallText {
//   color: $color-grey;
//   font-size: $font-size-base-lg;
//   padding: .4rem 1rem .4rem 1rem;
//   font-weight: 300;
// }

// .summaryText {
//   font-size: $font-size-lg-xl;
//   font-weight: 300;
//   padding: .4rem 1rem .4rem 1rem;
// }

// .itemPanelStats {
//   font-size: $font-size-super-big;
//   font-weight: 300;
// }

// .itemPanelTitle {
//   color: $color-grey;
//   text-transform: uppercase;
// }

// .itemPanelNotification {
//   position: relative;
//   top: -20px;
//   left: -6px;
//   padding: 5px 8px;
//   border-radius: 50%;
//   background: rgba($color-primary, .9);
//   color: $color-white;
//   font-size: $font-size-base;
// }

// .scroll {
//   max-height: 80vh;
//   overflow-y: scroll;
// }

// .panel {
//   border: 1px solid $color-border-grey;
//   border-radius: 5px;
//   padding-top: $spacing-sm;
//   background: $color-white;
//   margin-bottom: $spacing-lg;
//   overflow: auto;
//   min-height: 150px;
//   height: 100%;
// }

// .panelTitle {
//   font-weight: 500;
//   padding-bottom: .5rem;
//   margin-left: $spacing-md;
//   letter-spacing: 1px;
// }

// .panelErrorText {
//   margin: $spacing-lg;
// }

// .itemRow {
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap
// }

// .sameHeightRow,
// .flexColumn,
// .flex {
//   @include md {
//     display: flex;
//   }
// }

// .sameHeightRow {
//   @include md {
//     flex-wrap: wrap;
//   }
// }

// .flexColumn {
//   @include md {
//     flex-direction: column;
//   }
// }

// .marginTop {
//   margin-top: $spacing-xl;
// }


// .appContent {
//   padding: $spacing-md;
//   background: $color-light-grey;
//   height: 100vh;
//   background: #F8FAFF;
//   // min-height: calc(100vh - 56px);
// }

// .line {
//   position: relative;
//   border: .01px solid #ddd;
// }

// .skills {
//   color: $color-blue;
//   border: 1px solid $color-blue;
// }

// .iconsContainer {
//   margin: 0px $spacing-lg;
//   padding-top: 20px;
// }

// .badgesContainer {
//   padding-left: 1rem;
//   padding-right: 1rem;
//   clear: both;
//   float: left;
//   display: block;
//   position: relative;
//   padding-bottom: .4rem;
// }

// .buttonContainer {
//   margin-left: 1rem;
//   margin-right: 1rem;
//   align-items: center;
//   justify-content: center;
//   horizontal-align: middle;
// }

// .errorMessage {
//   color: $color-primary;
//   text-align: center;
//   margin-top: $spacing-lg;
//   margin-bottom: $spacing-lg;
// }

// .spacer {
//   margin: 5px !important;
//   margin-bottom: 10px !important;
//   font-size: .8rem !important;
//   align-items: center;
//   justify-content: center;
// }

//- New classes



.appContent {
  padding: 0 1.125rem 1.125rem 1.1rem;
  background: $color-light-grey;
  height: 100vh;
  background: #F8FAFF;
  font-size: 14px;
}

.dashboardContainer {
  // display: grid;
  // grid-auto-flow: column;
  // grid-template-columns: 10% auto 3fr;
  display: flex;
  flex-direction: row;
}

.positionRelative {
  position: relative;
}

.mobileAppContent {
  display: none;
}

@media screen and (max-width: 956px) {

  .appContent {
    display: none;
  }

  .mobileAppContent {
    display: block;
  }
}