@import "./base";

.header {
  background-color: $color-primary-red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-xl;
}

.errorIcon {
  height: 70px;
}

.body {
  color: $color-black;
  text-align: center;
  padding: $spacing-lg $spacing-md;
}

.okButton {
  width: 120px;
}
