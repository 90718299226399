
.recordNowButton {
  font-size: .9em;
  background: #6196FE;
  color: white;
  border: 1px solid #6196FE;
  border-radius: 10px;
  padding: .6em 1em;
  width: 95%;
}

.recordNowButton:focus {
  outline: none;
}

.expiredButton {
  font-size: .9em;
  background: #d2d2d2;
  color: #797979;
  border-radius: 10px;
  padding: .6em 1em;
  border: 0;
  width: 95%;
}

.expiredButton:focus {
  outline: none;
}

.viewDetailsButton {
  font-size: .9em;
  background: #EBFBEE;
  color: #2B8A3E;
  border: 1px solid #40C057;
  border-radius: 10px;
  padding: .6em 1em;
  width: 95%;
}

.viewDetailsButton:focus {
  outline: none;
}

.processingButton {
  font-size: 1em;
  background: #F2F3F7;
  color: #FFFFFF;
  border: 1px solid #F2F3F7;
  border-radius: 10px;
  padding: .6em 1em;
  cursor: not-allowed;
  color: #343a40;
}

.processingButton:focus {
  outline: none;
}

.companiesLogo {
  width: 2.5em;
  margin-right: 1em;
}

.interviewListContainer {
  width: 100%;
  margin: .5rem 0;
  padding: 1rem 0rem;
}

.dropdownSection {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 1rem;
}

.table {
  width: 100%;
  border-radius: 20px;
}

.tableHeader {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: .5rem;
  background: #F2F3F7;
  border-radius: 20px 20px 0 0;
  padding-left: 1em;
  grid-auto-columns: 3fr 2fr 1fr 1.5fr 1.5fr;
}

.tableRow {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: .5rem;
  grid-auto-columns: 3fr 2fr 1fr 1.5fr 1.5fr;
}

.tableBody {
  overflow-y: scroll;
  overflow: scroll;

  height: 17rem;
  border: 1px solid;
  border: 1px solid #F2F3F7;
  border-radius: 20px;
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
}

.tableContent {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.companyLogoName {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  border: 0px;
}

.companyLogoName:focus {
  outline: none;
  border: 0px;
}

.mobileScreenInterviewListContainer {
  display: none;
}

.mobileInterviewDetails {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 1.2rem 1rem;
  margin: 1rem 1.4rem;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .5rem 0rem;
  gap: .3rem;
}

.interviewStatus {
  // background: rgba(97, 150, 254, .1);
  // border: 1px solid rgba(97, 150, 254, .3);
  // border-radius: 35px;
  padding: .4rem 1rem;
  color: #6196FE;
  font-size: .8em;
}

.interviewRole {
  color: #1C2640;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 27.6px;
}

.companyNameAndLogo {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.interviewActionButton {
  background: rgba(97, 150, 254, .1);
  border: 1px solid rgba(97, 150, 254, .3);
  border-radius: 35px;
  padding: .4rem 1rem;
  color: #6196FE;
  font-size: 1em;
}

.dateText {
  color: #9BA4BA;
  font-weight: 400;
  font-size: .9em;
  line-height: 14px;
  margin-bottom: .3rem;
}

.interviewDate {
  color: #1C2640;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 16px;
}

.dropdownFilter {
  padding: .5rem 1rem;
  border-radius: 12px;
  border: 1px solid #7A91AB4D;
  height: 2.7rem;
}

.dropdownFilter:focus {
  outline: none;
}

@media screen and (min-width: 1441px) {
  
  .table {
    font-size: 1.2em;
  }

  .tableHeader {
    font-weight: 500;
  }

}

@media screen and (max-width: 956px) {

  .interviewListContainer {
    display: none;
  }

  .mobileScreenInterviewListContainer {
    display: block;
    padding-bottom: 1.5rem;
  }

  .mobileInterviewList {
    margin: 1.5rem 0rem;
  }

  .recordNowButton {
    font-size: .9em;
  }
}