@import "_base.scss";

.Toastify {

  span.glyphicon {
    font-size: $font-size-xxxl;
  }
}

.successToast {
  border-radius: .375rem !important;
  box-shadow: 0 0 0 1px $color-green !important;
}

.successToastBody {
  color: $color-green;
}

.successToastProgress {
  background: linear-gradient(135deg, $color-green 0%, rgb(128, 232, 185) 100%) !important;
}

.alertToast {
  border-radius: .375rem !important;
  box-shadow: 0 0 0 1px $color-primary-red !important;
}

.alertToastBody {
  color: $color-primary-red;
}

.alertToastProgress {
  background: linear-gradient(135deg, $color-primary-red 0%, $color-primary-light-red 100%) !important;
}