.testing {
  padding: 1rem;
  border-right: 1px solid #E8ECF3;
} 

.sliderContainer {
  padding: 1.2rem;
  border-radius: 10px;
}

.sliderContainer:hover {
  cursor: pointer;
  background-color: #fbfbfb;
  border-radius: 10px;
}

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: .5rem;
}

.newFlex {
  display: grid;
  grid-template-columns: 10fr 2fr;
}

.slider {
  width: 100%;
  height: 9px;
  background-color: #e0e0e0;
  position: relative;
  border-radius: 2px;
}

.sliderFill {
  height: 100%;
  border-radius: 2px;
}

.value {
  margin-left: 1rem;
  font-weight: 500;
  font-size: .9em;
}

.totalResumeScore {
  padding: 2rem 1.2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalResumeScore:hover {
  cursor: pointer;
  background-color: #fbfbfb;
  border-radius: 10px;
}

.totalScoreLabel,
.totalScoreValue {
  color: #080B2E;
  font-weight: 500;
  font-size: 1em;
}

.pointDescription {
  padding: 1.1rem;
  background-color: #EEF0F5;
  border-radius: 10px;
  max-height: 200px;
}

.selectedTitle {
  color: #080B2E;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: .5rem;
}

.selectedDesc {
  color: #5F6981;
  font-weight: 500;
  font-size: 1em;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: .5rem;
}

.selectedDesc::-webkit-scrollbar-track {
  padding: 2px 0;
}

.selectedDesc::-webkit-scrollbar {
  width: 6px;
}

.selectedDesc::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px #cdd3e1;
  background-color: #cdd3e1;
}