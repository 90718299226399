@import "./_base.scss";

.glyphiconSm {
  font-size: 10px;
}

.glyphiconMd {
  font-size: 18px;
}

.glyphiconLg {
  font-size: 25px;
}

.icon {
  color: $color-text-grey;
}
