.root {
  max-height: 100%;
  display: block;
}

.flexContainer {
  display: flex;
  margin-right: 3.125rem;
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
}


.blurFlexContainer {
  display: flex;
  margin-left: 3.125rem;
  margin-right: 3.125rem;
  width: 96vw;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  height: 45vh;
}

.buttonContainer {
  display: flex;
  width: fit-content;
  margin-top: 1.5625rem;
  height: 3.375rem;
  align-items: baseline;
  margin-left: 2rem;
}

.buttonText {
  width: 82px;
  height: 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.payButton {
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  gap: 10px;
  width: 8.625rem;
  height: 3.375rem;
  background: #6196FE;
  border-radius: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  outline: 0;
  border: 0;
  margin-left: 1.875rem;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    outline: 0;
  }

}

.scoreWrapper {
  width: 100%;
  background-image: url(https://interviewer-ai-assets.s3.ap-southeast-1.amazonaws.com/candidateDetailScoreBlur.png);
  position: relative;
  background-size: 100%;
  padding-top: 50%;
  height: 0;
  margin-bottom: 2rem;

}

.scoreMidWrapper {
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(97, 150, 254, .1);
  -webkit-backdrop-filter: blur(44px);
  backdrop-filter: blur(4px);
  border-radius: 30px;
}

.offerContentWrapper {
  position: absolute;
  top: 10%;
}

.blurFlexContainer {
  display: flex;
  width: 94%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  justify-content: space-between;
}

.helpText {
  font-size: 1.8em;
  margin: 1em;
}

.offerText {
  width: 30%;
  margin-left: 1.875rem;
  margin-top: 1.5625rem;
  font-size: 1.1em;
  font-weight: 300;

}

.priceText {
  font-size: 1em;
  line-height: 20px;
  text-decoration-line: line-through;
  color: #080B2E;
  flex: none;
  margin-left: .75rem;
  margin-bottom: 0;
  margin-top: 1em;
}

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: .5em;
  width: 25vw;
  height: max-content;
  background: #FFFFFF;
  border: 1px solid rgba(238, 240, 245, .2);
  border-radius: 30px;

  p {
    margin-bottom: 0;
  }
}

.cardImg {
  width: 3.6rem;
  height: 3.7rem;
}

.cardText {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 28px;
  color: #1C2640;
}

.headingText {
  font-size: 1.5em;
  display: inline-block;
  margin-left: 1em;
  font-weight: bold;
  margin-bottom: 3rem;

}

.notAvailableText {
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  color: #16182F;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 10%;
}

.scoreDiv {
  // width: 33%;
  height: max-content;
  overflow: auto;
  padding: 0 2em;
}

.horizontalLine {
  display: none;
}



.scoreContainer {
  border: 1px solid #e8ecf3;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin: 0 3em;
}

.scoreContainer > * {
  flex-basis: 0;
  flex-grow: 1;
}

.resumeContainer,

.workmapContainer {
  border-right: 1px solid #e8ecf3;
  padding: 0 2em;
}

.workmapScoreDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
}

.workmapScoreDiv > * {
  margin: 1.5rem 0rem;
}

@media screen and (min-width:1257px) {

  .scoreDiv {
    width: 33%;
    height: max-content;
    overflow: auto;
  }

  .scoreDiv::-webkit-scrollbar {
    width: .375rem;
    height: 3vh;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scoreDiv {
    scrollbar-width: .0063rem;  /* Firefox */
  }
}

.verticalLine {
  width: 1px;
  height: 23.75rem;
  background: #E4E8F0;
  margin-right: 2vw;
}



.workmapText {
  margin-top: 1rem;
  // position: absolute;
  text-align: center;
}

.aiVideoScore {
  padding: 0 2em;
}

.workmapScoreText {
  // width: 2.1875rem;
  // height: 3.125rem;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 30px;
  // line-height: 3.125rem;
  // display: flex;
  // align-items: center;
  // text-align: center;
  // letter-spacing: -.03em;
  // color: #16182F;
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: -80px;
}

.ringContainer {
  position: relative;
}

.workmapScoreText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4em;
}

%progressBarBlue {
  height: 4px;
  width: 100%;
  background: #7989B1;
  border-radius: 2px;
}

.resumeContainer > :nth-child(2) > div:first-child {
  flex-direction: column !important;
}

.progressBarBlue {
  height: 4px;
  width: 100%;
  background: #7989B1;
  border-radius: 2px;
}

.progressBarBlue::-webkit-progress-value {
  background: #6196FE;
  border-radius: .25rem;
}

.progressBarBlue::-webkit-progress-bar {
  background: #eef0f5;
  // opacity: 0.2;
  border-radius: .25rem;
  border-radius: 2px;
}

.progressBarBlue::-moz-progress-bar {
  background: #6196FE;
  border-radius: .25rem;
}

.progressBarYellow {
  @extend %progressBarBlue;
}

.progressBarYellow::-moz-progress-bar {
  background: #FFCE54;
  border-radius: .25rem;
}

.progressBarYellow::-webkit-progress-value {
  background: #FFCE54;
  border-radius: .25rem;
}

.progressBarYellow::-webkit-progress-bar {
  background: #eef0f5;
  border-radius: .25rem;
  border-radius: 2px;
}


.progressBarLightRed {
  @extend %progressBarBlue;
}

.progressBarLightRed::-webkit-progress-value {
  background: #ED5565;
  border-radius: .25rem;
}

.progressBarLightRed::-moz-progress-bar {
  background: #ED5565;
  border-radius: .25rem;
}

.progressBarLightRed::-webkit-progress-bar {
  background: #eef0f5;
  border-radius: .25rem;
  border-radius: 2px;
}

.progressBarPink {
  @extend %progressBarBlue;
}

.progressBarPink::-webkit-progress-value {
  background: #EC87C0;
  border-radius: .25rem;
}

.progressBarPink::-moz-progress-bar {
  background: #EC87C0;
  border-radius: .25rem;
}

.progressBarPink::-webkit-progress-bar {
  background: #eef0f5;
  border-radius: .25rem;
  border-radius: 2px;
}

.purchaseBanner {
  width: 100%;
  height: 30%;
}


.aiVideoScoreSection {
  width: 100%;
  background-color: #f8f9fb;
  padding: 1em 2em 2em;
  border-radius: 1em;
  margin: auto auto 1em;
}


@media screen and (max-width: 750px) {

  .root {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }

  .flexContainer {
    display: block;
    margin-right: 0;
    background: #FFFFFF;
    border-radius: 1.25rem;
  }

  .scoreDiv {
    width: 100%;
    height: fit-content;
    padding-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    min-height: 40rem;
  }

  .blurFlexContainer {
    display: block;
    width: 100vw;
    margin-top: 30px;
    margin-left: 0;
  }

  .workmapScoreDiv {
    padding-right: 0;
    
  }

  .card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 90vw;
    height: 196px;
    background: #FFFFFF;
    border: 1px solid rgba(238, 240, 245, .2);
    backdrop-filter: blur(60px);
    border-radius: 30px;
    margin-top: 50px;
  }

  .helpText {
    width: max-content;
    height: 45px;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 45px;
    display: flex;
    align-items: center;
    letter-spacing: -.03em;
    color: #1C2640;
    flex: none;
    order: 0;
    align-self: stretch;
    margin-left: 1.875rem;
  }

  .scoreWrapper {
    z-index: 9;
    width: 100vw;
    height: 150vh;
    margin-top: 460px;
    border-radius: 30px;
    backdrop-filter: blur(10px);
    padding-top: 3.125rem;
    margin-left: 1rem;

  }


  .offerText {
    width: 80%;

  }

  .verticalLine {
    display: none;
  }

  .headingText {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    text-align: center;
    width: inherit;
  }

  .horizontalLine {
    display: block;
  }

  .aiVideoScore {
    padding: 0 1rem;
  }


  .scoreContainer {
    margin: 0 1em;
    flex-direction: column;

    .scoreDiv,

    .aiVideoScore {
      border-top: 2px solid #e8ecf3;
      margin: 2em 0em;
    }
  }
}

.scoreHeadings {
  font-size: 1.4em;
}
