.paginationContainer {
  display: flex;
  list-style-type: none;
  padding: 0rem !important;

  .dots {
    color: rgba(0, 0, 0, .87);
    letter-spacing: .01071em;
    font-weight: 500;
    position: relative;
    display: flex;
    border-radius: 100%;
    width: 50px;
    line-height: 50px;
    text-align: center;
    z-index: 2;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 1.5%;
    font-weight: 600;
    transition: transform .2s;
  }

  .paginationItem {
    color: rgba(0, 0, 0, .87);
    letter-spacing: .01071em;
    font-weight: 500;
    position: relative;
    display: flex;
    border-radius: 100%;
    width: 50px;
    line-height: 50px;
    text-align: center;
    z-index: 2;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    justify-content: center;
    align-items: center;
    margin-right: 1.5%;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, .08);
    transition: transform .2s;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, .04);
      cursor: pointer;
      transform: scale(1.09);
      font-weight: 700;
      border: 0;
    }

    &.selected {
      background-color: rgba(0, 0, 0, .08);
      font-weight: 700;
      border: 0;
    }
  }
}

.arrow {

  &::before {
    position: relative;
    content: "";
    display: inline-block;
    width: .4em;
    height: .4em;
    border-right: .12em solid rgba(0, 0, 0, .87);
    border-top: .12em solid rgba(0, 0, 0, .87);
  }

  &.left {
    transform: rotate(-135deg) translate(-50%);
  }

  &.right {
    transform: rotate(45deg);
  }
}

.paginationItem.disabled {
  pointer-events: none;

  .arrow::before {
    border-right: .12em solid rgba(0, 0, 0, .43);
    border-top: .12em solid rgba(0, 0, 0, .43);
  }

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}