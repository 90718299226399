* {
  padding: 0;
  margin: 0;
}

.backgroundHeader {
  width: 100%;
  height: 15vh;
  background-color: #181a31;
}

.mainContainer {
  background-color: #f8faff;
}

.contentSection {
  width: 76%;
  margin: auto;
  background-color: #ffffff;
  z-index: 3;
  position: relative;
  top: -3.5rem;
  border-radius: 15px;
  padding: 2rem;
}

.companyLogo {
  height: 90px;
  width: 90px;
}

.companyLogoSection {
  padding-right: 1.4rem;
  padding-bottom: 1rem;
}

.companyName {
  color: #16182f;
  font-weight: 700;
  font-size: 1.5em;
}

.companyText {
  color: #5f6981;
  font-size: 1em;
  font-weight: 400;
}

.aboutHeading {
  color: #16182f;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 40px;
}

.companyDiscription {
  font-weight: 400;
  font-size: .9em;
  line-height: 22px;
}

.jobCardContainer {
  width: 76%;
  margin: auto;
  background-color: #ffffff;
  z-index: 3;
  position: relative;
  top: -2.3rem;
  border-radius: 15px;
  padding: 2rem;
}

.availableJobTitle {
  font-size: 1.5em;
  color: #16182f;
  margin-bottom: 2rem;
}

.card {
  border: 1px solid rgba(97, 150, 254, .2);
  background-color: #f8f9fb;
  padding: 1.2rem 1.5rem;
  border-radius: 25px;
  min-width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  background-color: rgba(97, 150, 254, .1);
  transform: scale(1.02);
  z-index: 4;
  box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
    rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
    rgba(0, 0, 0, .1) 0px 20px 30px 0px;
  transition: all .2s linear;
}

.cardHeading {
  font-size: 1.2em;
  font-weight: 400;
  color: #1c2640;
  line-height: 30px;
}

.cardPlace {
  padding: .5rem .9rem;
  margin-top: .4rem;
  margin-right: .8rem;
  color: #1c2640;
  background-color: white;
  box-shadow: 0px 4px 20px #e7eefe;
  border-radius: 20px;
  font-size: .8em;
}

.cardDescription {
  color: #5f6981;
  font-weight: 400;
  font-size: .9em;
  max-height: 100px;
  overflow: hidden;
  position: relative;
}

.cardDiscriptionTxt {
  color: #1c2640;
  font-weight: 500;
  font-size: 1.15em;
  line-height: 32px;
  margin-top: .8rem;
}

.cardExpTxt {
  font-size: #1c2640;
  font-size: .9em;
  font-weight: 400;
}

.cardApplyBtn {
  padding: .6rem 1.5rem;
  background: #6196fe;
  border-radius: 10px;
  color: white;
  border: 0;
}

.cardApplyBtn:hover {
  background: #387aff;
  transition: all .2s linear;
  box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
    rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
    rgba(0, 0, 0, .1) 0px 20px 30px 0px;
}

.cardApplyBtn:focus {
  outline: none;
}

.cardExpValue {
  font-size: 1.1em;
  font-weight: 400;
  color: #1c2640;
}

.addContainer {
  width: 90%;
  margin: auto;
  // background-image: linear-gradient(rgba(22, 21, 21, .5), rgba(0, 0, 0, .5)),
  //   url("../assets/CompanyDetailsBannerImg.svg");
  background-size: cover;
  background-position: center;
  z-index: 3;
  position: relative;
  top: -1rem;
  border-radius: 15px;
  padding: 4rem 3rem;
  color: white;
  padding-bottom: 1rem !important;
  padding-top: 2.5rem !important;
}

.addContainerHeadingTxt {
  color: #ffffff;
  font-size: 2.5em;
  width: 50%;
  line-height: 40px;
  font-weight: 700;
}

.addContainerButton {
  background-color: #6196fe;
  padding: .8rem 1.5rem;
  border: 0;
  color: white;
  border-radius: 15px;
  margin-right: 1rem;
}

.addContainerButton:hover {
  background-color: #3f7fff;
  box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
    rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
    rgba(0, 0, 0, .1) 0px 20px 30px 0px;
  transition: all .2s linear;
}

.addContainerButton:focus {
  outline: none;
}

.addContainerOriginalPrice {
  text-decoration-line: line-through;
  color: white;
  font-size: 1.25em;
}

.addContainerInfoTxt {
  color: white;
  font-size: 1.25em;
  line-height: 30px;
  font-weight: 400;
  width: 50%;
  margin-top: 2rem;
}

.addBannerCard {
  border: 1px solid rgba(238, 240, 245, .2);
  padding: 2rem;
  border-radius: 30px;
  backdrop-filter: blur(60px);
  margin-bottom: 1rem;
  display: inline-block;
  width: 90%;
  margin: 1rem;

}

.addCardContainer {
  display: flex;
}

.addBannerCardTxt {
  color: white;
  font-size: 1.25em;
  line-height: 28px;
  font-weight: 400;
  margin-top: 1rem;
}

.readmoreText {
  color: #6196fe;
  cursor: pointer;
  text-decoration: underline;
  display: block;
}

.viewJobDetailsText {
  color: #6196fe;
  cursor: pointer;
  text-decoration: underline;
  display: block;
}

.errorContainer {
  background-color: rgb(200, 200, 200);
  width: 100vw;
  height: 100vh;
  color: rgb(0, 0, 0);
}

.errorCard {
  width: 300px;
  border-radius: 15px;
  padding: 1rem 2rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
    rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
    rgba(0, 0, 0, .1) 0px 20px 30px 0px;
}

.errorTxt1 {
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: .4rem !important;
}

.errorTxt2 {
  text-align: center;
  font-size: 1.1em;
}

.footerBanner {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 76%;
    height: 100%;
  }
}

.buttonContainer {
  justify-content: space-between;
}

.buttonContainerLeftAligned {
  justify-content: end;
}

.modalContainer {
  position: absolute;
  display: block !important;
  border: 1px solid rgba(97, 150, 254, .2);
  max-width: 100%;
  z-index: 100;
  width: 21vw;
  height: 27vh;
  background-color: #FFFFFF;
  border-radius: 25px;
  margin-left: -1rem;
}

.qrImageStyles {
  margin: auto;
  width: 100%;
  height: 80%;
  margin-top: 1.5rem;
}

.nonDisplay {
  display: none;
}

.defaultBanner {
  width: 76%;
  object-fit: contain;
  margin: auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
//media queries

@media screen and (min-width: 1450px) {

  .modalContainer {
    height: 24vh;
    height: 20vh ;
  }
}

@media (max-width: 1400px) {

  .contentSection {
    width: 78%;
  }

  .jobCardContainer {
    width: 78%;
  }

  .addContainer {
    width: 78%;
    margin: auto;
    // background-image: linear-gradient(rgba(22, 21, 21, .5), rgba(0, 0, 0, .5)),
    //   url("../assets/CompanyDetailsBannerImg.svg");
    background-size: cover;
    background-position: center;
    z-index: 3;
    position: relative;
    top: -1rem;
    border-radius: 15px;
    padding: 3rem;
    color: white;
  }

  .companyLogo {
    height: 80px;
    width: 80px;
  }

  .companyLogoSection {
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .companyName {
    color: #16182f;
    font-weight: 700;
    font-size: 1.3em;
  }

  .companyText {
    color: #5f6981;
    font-size: 1em;
    font-weight: 400;
  }

  .aboutHeading {
    color: #16182f;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 40px;
  }

  .companyDiscription {
    font-weight: 400;
    font-size: .8em;
    line-height: 22px;
    max-height: 100px;
    overflow: hidden;
    position: relative;
  }

  .viewJobDetailsText {
    color: #6196fe;
    cursor: pointer;
    text-decoration: underline;
    display: block;
  }

  .availableJobTitle {
    font-size: 1.2em;
    color: #16182f;
  }

  .cardHeading {
    font-size: 1.1em;
    font-weight: 400;
    color: #1c2640;
    line-height: 30px;
  }

  .cardPlace {
    padding: .5rem .9rem;
    margin-top: .3rem;
    margin-right: .7rem;
    color: #1c2640;
    background-color: white;
    box-shadow: 0px 4px 20px #e7eefe;
    border-radius: 20px;
    font-size: .7em;
  }

  .cardDescription {
    color: #5f6981;
    font-weight: 400;
    font-size: .8em;
  }

  .cardDiscriptionTxt {
    color: #1c2640;
    font-weight: 500;
    font-size: 1.01em;
    line-height: 32px;
    margin-top: .8rem;
  }

  .cardApplyBtn {
    padding: .5rem 1.3rem;
    background: #6196fe;
    border-radius: 10px;
    color: white;
    border: 0;
    font-size: .9em;
  }

  .addImgContainer {
    padding-right: 1.7rem;
  }

  .textForAdd {
    padding: 1rem;
  }

  .textForAddMain {
    font-size: 2.5em;
    font-weight: 400;
    line-height: 45px;
  }

  .textforAddMuted {
    font-size: 1em;
    line-height: 14.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .8rem 1.7rem;
    border: 0;
  }

  .textAddBtnContainer {
    margin-top: 2.2rem;
  }

}

@media (max-width: 1200px) {

  .addImgContainer {
    padding-right: 1.3rem;
  }

  .textForAdd {
    padding: 1rem;
  }

  .textForAddMain {
    font-size: 2.4em;
    font-weight: 400;
    line-height: 45px;
  }

  .textforAddMuted {
    font-size: .8em;
    line-height: 24.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .8rem 1.7rem;
    border: 0;
  }

  .textAddBtnContainer {
    margin-top: 2rem;
  }

}

@media (max-width: 1000px) {

  .addContainer {
    padding: 2rem;
  }

  .addImgContainer {
    padding-right: 1rem;
  }

  .textForAdd {
    padding: .8rem;
  }

  .textForAddMain {
    font-size: 2.1em;
    font-weight: 400;
    line-height: 45px;
  }

  .textforAddMuted {
    font-size: .8em;
    line-height: 14.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .8rem 1.7rem;
    border: 0;
  }

  .textAddBtnContainer {
    margin-top: 2rem;
  }

  .addContainerHeadingTxt {
    color: #ffffff;
    font-size: 2.3em;
    width: 70%;
    line-height: 40px;
    font-weight: 700;
  }

  .addContainerButton {
    background-color: #6196fe;
    padding: .8rem 1.5rem;
    border: 0;
    color: white;
    border-radius: 15px;
    margin-right: 1rem;
  }

  .addCardContainer {
    display: flex;
    flex-direction: column;
  }

  .addContainerOriginalPrice {
    text-decoration-line: line-through;
    color: white;
    font-size: 1.15em;
  }

  .addContainerInfoTxt {
    color: white;
    font-size: 1.25em;
    line-height: 30px;
    font-weight: 400;
    width: 70%;
    margin-top: 2rem;
  }

  .addBannerCard {
    border: 1px solid rgba(238, 240, 245, .2);
    padding: 1rem 2rem;
    border-radius: 30px;
    backdrop-filter: blur(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .addBannerCardTxt {
    color: white;
    font-size: 1.25em;
    line-height: 28px;
    font-weight: 400;
    margin-top: 1rem;
  }

}

@media (max-width: 786px) {

  .backgroundHeader {
    width: 100%;
    height: 20vh;
    background-color: #181a31;
  }

  .modalContainer {
    height: 14rem;
    width: 17.8rem;
  }

  .contentSection {
    width: 78%;
    margin: auto;
    background-color: #ffffff;
    z-index: 3;
    position: relative;
    top: -5.5rem;
    border-radius: 15px;
    padding: 2rem;
  }

  .jobCardContainer {
    width: 78%;
    margin: auto;
    background-color: #ffffff;
    z-index: 3;
    position: relative;
    top: -4.3rem;
    border-radius: 15px;
    padding: 2rem;
  }

  .addContainer {
    width: 100%;
    margin: auto;
    // background-color: #457ce8;
    z-index: 3;
    position: relative;
    top: -3rem;
    border-radius: 15px;
    padding: 2.5rem;
    color: white;
  }

  .companyLogo {
    height: 70px;
    width: 70px;
  }

  .companyLogoSection {
    padding-right: 1.1rem;
    padding-bottom: 1.1rem;
  }

  .companyName {
    color: #16182f;
    font-weight: 700;
    font-size: 1.2em;
  }

  .companyText {
    color: #5f6981;
    font-size: .9em;
    font-weight: 400;
  }

  .aboutHeading {
    color: #16182f;
    font-size: 1em;
    font-weight: 500;
    line-height: 40px;
  }

  .companyDiscription {
    font-weight: 400;
    font-size: .8em;
    line-height: 22px;
  }

  .availableJobTitle {
    font-size: 1.1em;
    color: #16182f;
  }

  .cardHeading {
    font-size: 1.1em;
    font-weight: 400;
    color: #1c2640;
    line-height: 30px;
    margin-bottom: .5rem !important;
  }

  .card {
    border: 1px solid rgba(97, 150, 254, .2);
    background-color: #f8f9fb;
    padding: 1rem 1.8rem;
    border-radius: 25px;
    min-width: 70%;
    max-height: 100%;
  }

  .cardPlace {
    padding: .5rem .9rem;
    margin-top: .3rem;
    margin-right: .7rem;
    color: #1c2640;
    background-color: white;
    box-shadow: 0px 4px 20px #e7eefe;
    border-radius: 20px;
    font-size: .7em;
  }

  .cardDescription {
    color: #5f6981;
    font-weight: 400;
    font-size: .8em;
  }

  .cardDiscriptionTxt {
    color: #1c2640;
    font-weight: 500;
    font-size: 1.01em;
    line-height: 32px;
    margin-top: .8rem;
  }

  .cardApplyBtn {
    padding: .5rem 1.5rem;
    background: #6196fe;
    border-radius: 10px;
    color: white;
    border: 0;
    font-size: .8em;
  }

  .addContainer {
    padding: 1.5rem;
  }

  .addImgContainer {
    padding-right: 0;
  }

  .textForAdd {
    padding: .8rem;
    margin-top: 1rem;
  }

  .textForAddMain {
    font-size: 2em;
    font-weight: 400;
    line-height: 45px;
  }

  .textforAddMuted {
    font-size: .8em;
    line-height: 14.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .8rem 1.7rem;
    border: 0;
    font-size: .8em;
  }

  .textAddBtnContainer {
    margin-top: 1.7rem;
  }

  .addContainerHeadingTxt {
    color: #ffffff;
    font-size: 2em;
    width: 70%;
    line-height: 40px;
    font-weight: 700;
  }

  .addContainerButton {
    background-color: #6196fe;
    padding: .8rem 1.5rem;
    border: 0;
    color: white;
    border-radius: 15px;
    margin-right: 1rem;
  }

  .addContainerOriginalPrice {
    text-decoration-line: line-through;
    color: white;
    font-size: 1.15em;
  }

  .addContainerInfoTxt {
    color: white;
    font-size: 1.25em;
    line-height: 30px;
    font-weight: 400;
    width: 70%;
    margin-top: 2rem;
  }

  .addBannerCard {
    border: 1px solid rgba(238, 240, 245, .2);
    padding: 2rem;
    border-radius: 30px;
    backdrop-filter: blur(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .addBannerCardTxt {
    color: white;
    font-size: 1.15em;
    line-height: 28px;
    font-weight: 400;
    margin-top: 1rem;
  }

}

@media (max-width: 480px) {

  .backgroundHeader {
    width: 100%;
    height: 30vh;
    background-color: #181a31;
  }

  .contentSection {
    width: 78%;
    margin: auto;
    background-color: #ffffff;
    z-index: 3;
    position: relative;
    top: -11.5rem;
    border-radius: 15px;
    padding: 2rem;
  }

  .jobCardContainer {
    width: 100%;
    margin: 0;
    background-color: #f8faff;
    position: relative;
    top: -10.3rem;
    padding: 3rem;
    box-shadow: none !important;
  }

  .addContainer {
    width: 100%;
    margin: auto;
    // background-color: #457ce8;
    z-index: 3;
    position: relative;
    top: -12rem;
    border-radius: 15px;
    padding: 2.5rem;
    color: white;
  }

  .companyLogo {
    height: 63px;
    width: 63px;
  }

  .companyLogoSection {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .companyName {
    color: #16182f;
    font-weight: 700;
    font-size: 1.1em;
  }

  .companyText {
    color: #5f6981;
    font-size: .8em;
    font-weight: 400;
  }

  .aboutHeading {
    color: #16182f;
    font-size: 1em;
    font-weight: 500;
    line-height: 40px;
  }

  .companyDiscription {
    font-weight: 400;
    font-size: .8em;
    line-height: 22px;
  }

  .availableJobTitle {
    font-size: 1.1em;
    color: #16182f;
  }

  .card {
    border: 1px solid rgba(97, 150, 254, .2);
    background-color: #f8f9fb;
    padding: 1rem 1.4rem;
    border-radius: 25px;
    min-width: 70%;
    max-height: 100%;
  }

  .cardPlace {
    padding: .5rem .9rem;
    margin-top: .3rem;
    margin-right: .7rem;
    color: #1c2640;
    background-color: white;
    box-shadow: 0px 4px 20px #e7eefe;
    border-radius: 20px;
    font-size: .7em;
  }

  .cardDescription {
    color: #5f6981;
    font-weight: 400;
    font-size: .76em;
  }

  .cardDiscriptionTxt {
    color: #1c2640;
    font-weight: 500;
    font-size: 1em;
    line-height: 32px;
    margin-top: .8rem;
  }

  .cardApplyBtn {
    padding: .5rem 1.6rem;
    background: #6196fe;
    border-radius: 10px;
    color: white;
    border: 0;
    font-size: .8em;
  }

  .cardExpTxt {
    font-size: #1c2640;
    font-size: .8em;
    font-weight: 400;
  }

  .cardExpValue {
    font-size: 1em;
    font-weight: 400;
    color: #1c2640;
  }

  .addContainer {
    padding: 1.5rem;
  }

  .addImgContainer {
    padding-right: 0;
  }

  .textForAdd {
    padding: .8rem;
    margin-top: 1rem;
  }

  .textForAddMain {
    font-size: 1.8em;
    font-weight: 400;
    line-height: 35px;
  }

  .textforAddMuted {
    font-size: .7em;
    line-height: 10.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .8rem 1.5rem;
    border: 0;
    font-size: .7em;
  }

  .textAddBtnContainer {
    margin-top: 1.7rem;
  }

  .addContainerHeadingTxt {
    color: #ffffff;
    font-size: 1.8em;
    width: 80%;
    line-height: 40px;
    font-weight: 700;
  }

  .addContainerButton {
    background-color: #6196fe;
    padding: .8rem 1.5rem;
    border: 0;
    color: white;
    border-radius: 15px;
    margin-right: 1rem;
  }

  .addContainerOriginalPrice {
    text-decoration-line: line-through;
    color: white;
    font-size: 1em;
  }

  .addContainerInfoTxt {
    color: white;
    font-size: 1.25em;
    line-height: 30px;
    font-weight: 400;
    width: 80%;
    margin-top: 2rem;
  }

  .addBannerCard {
    border: 1px solid rgba(238, 240, 245, .2);
    padding: 2rem;
    border-radius: 30px;
    backdrop-filter: blur(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .addBannerCardTxt {
    color: white;
    font-size: 1.15em;
    line-height: 28px;
    font-weight: 400;
    margin-top: 1rem;
  }

}

@media (max-width: 300px) {

  .backgroundHeader {
    width: 100%;
    height: 50vh;
    background-color: #181a31;
  }

  .contentSection {
    width: 78%;
    margin: auto;
    background-color: #ffffff;
    z-index: 3;
    position: relative;
    top: -17.5rem;
    border-radius: 15px;
    padding: 2rem;
  }

  .jobCardContainer {
    width: 100%;
    margin: 0;
    background-color: #f8faff;
    position: relative;
    top: -16.3rem;
    padding: 2rem;
    box-shadow: none !important;
  }

  .addContainer {
    width: 100%;
    margin: auto;
    // background-color: #457ce8;
    z-index: 3;
    position: relative;
    top: -18rem;
    border-radius: 15px;
    padding: 2.5rem;
    color: white;
  }

  .companyLogo {
    height: 53px;
    width: 53px;
  }

  .companyLogoSection {
    padding-right: .9rem;
    padding-bottom: .9rem;
  }

  .companyName {
    color: #16182f;
    font-weight: 700;
    font-size: .9em;
  }

  .companyText {
    color: #5f6981;
    font-size: .6em;
    font-weight: 400;
  }

  .aboutHeading {
    color: #16182f;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 40px;
  }

  .companyDiscription {
    font-weight: 400;
    font-size: .7em;
    line-height: 22px;
  }

  .availableJobTitle {
    font-size: 1.1em;
    color: #16182f;
  }

  .card {
    border: 1px solid rgba(97, 150, 254, .2);
    background-color: #f8f9fb;
    padding: 1rem 1.4rem;
    border-radius: 25px;
    min-width: 70%;
    max-height: 100%;
  }

  .cardPlace {
    padding: .5rem .9rem;
    margin-top: .3rem;
    margin-right: .6rem;
    color: #1c2640;
    background-color: white;
    box-shadow: 0px 4px 20px #e7eefe;
    border-radius: 20px;
    font-size: .6em;
  }

  .cardDescription {
    color: #5f6981;
    font-weight: 400;
    font-size: .76em;
  }

  .cardDiscriptionTxt {
    color: #1c2640;
    font-weight: 500;
    font-size: 1em;
    line-height: 32px;
    margin-top: .8rem;
  }

  .cardApplyBtn {
    padding: .5rem 1.6rem;
    background: #6196fe;
    border-radius: 10px;
    color: white;
    border: 0;
    font-size: .8em;
  }

  .cardExpTxt {
    font-size: #1c2640;
    font-size: .8em;
    font-weight: 400;
  }

  .cardExpValue {
    font-size: 1em;
    font-weight: 400;
    color: #1c2640;
  }

  .addContainer {
    padding: 1.2rem;
  }

  .addImgContainer {
    padding-right: 0;
  }

  .textForAdd {
    padding: .8rem;
    margin-top: .5rem;
  }

  .textForAddMain {
    font-size: 1.3em;
    font-weight: 400;
    line-height: 25px;
  }

  .textforAddMuted {
    font-size: .6em;
    line-height: 10.25px;
    font-weight: 400;
  }

  .textForAddBtn {
    color: #457ce8;
    background-color: white;
    border-radius: 15px;
    padding: .6rem 1.2rem;
    border: 0;
  }

  .textAddBtnContainer {
    margin-top: 1.7rem;
  }

  .errorCard {
    width: 250px;
    border-radius: 15px;
    padding: 1rem 2rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, .024) 0px 0px 0px 1px,
      rgba(0, 0, 0, .05) 0px 1px 0px 0px, rgba(0, 0, 0, .03) 0px 0px 8px 0px,
      rgba(0, 0, 0, .1) 0px 20px 30px 0px;
  }

}

.languageDropdownWrapper {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 4;
}

.languageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7a91ab;
  grid-gap: 1rem;
  gap: 1rem;
  align-self: stretch;
  position: relative;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
}

.languageChangeDropdown {
  border-radius: 5px;
  padding: .5rem;
  margin-left: .5rem;
  background-color: #FFFFFF;
  border: 1px solid rgb(213, 213, 213);
}

.FlagImg {
  // border-radius: 50% !important;
  width: 25px !important;
  height: 20px !important;
  object-fit: cover;
  filter: invert(0%) !important;
  margin-top: 0rem !important;
}

.languageDropdown {
  position: absolute;
  background: #FFFFFF;
  top: 4rem;
  right: 0;
  padding: 1rem .5rem;
  border: 1px solid rgba(0, 0, 0, .15);
  margin: 0;
  border-radius: 1em;
}

.languageChangeDropdown:hover {
  cursor: pointer;
}

.languageDropdownContent {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  white-space: nowrap;
}

.languageDropdownContent:hover {
  background-color: #f5f5f5;
}
