

.appDrawerContainerOpen {
  height: 100vh;
  background-color: #F8FAFF;
  min-width: 13vw;
  // max-width: 15vw; // for mobile creating restriction
  position: sticky;
  top: 0;
}

.appDrawerContainerCollapsed {
  height: 100vh;
  background-color: #F8FAFF;
  min-width: auto;
  width: 3.8rem;
  position: sticky;
  top: 0;

  .navContainer {
    
    span {
      display: none !important; 
    }
  }
}



.navContainer {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 3em;
    color: #5F6981;
    text-decoration: none;
    margin-bottom: 1em;
    white-space: nowrap; // menu name will not break line

    .navItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 1em;
      gap: 1em;
    }

    span {
      // display: flex;
      // color:"#5F6981";
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.brandContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 4em 1em 2em .6em;
}

.navItemsBottom {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}





.transparentButton {
  border: 0px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  align-self: self-start;
  margin-left: 1rem;
  gap: .8rem;

  span {
    color: #5F6981;
  }
}

.transparentButton:focus {
  outline: none;
}


// For mobile version 


.backup {
  width: 70%;
  position: fixed;
  border-right: 1px solid;
  transform: translateX(-125%);
}

.signOutButton {
  background: transparent;
  border: 0px;
  display: flex;
  justify-content: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2em;
}

.signOutButton:focus {
  outline: none;
  border: 0px;
}

.brandMobileContainer {
  display: none;
}

@media screen and (min-width: 1441px) {

  .appDrawerContainerOpen,
  .appDrawerContainerCollapsed {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 956px) {

  .appDrawerContainerOpen {
    position: fixed;
    width: 80%;
    z-index: 11;
    background: grey;
    height: 100%;
    padding-left: 1.2rem;
    background-color: #f8faff ;
    box-shadow: 1px 0px 7px rgba(0,0,0,.5); 
    transform: translateX(0);
    transition: transform .3s ease-out;
  }

  .transparentButton {
    display: none;
  }

  .brandMobileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 4em 1em 2em .6em;
  }

  .brandContainer {
    display: none;
  }
}