@import "_base.scss";

.svg {
  display: block;
  margin: 20px auto;
}

.baseCircle {
  fill: $color-border-grey;
}

.progressCircle {
  fill: none;
}

.middleCircle {
  fill: white;
}

.scoreDisplay {
  font-size: $font-size-xxxl;
  text-anchor: middle;
  fill: $color-black;
}

.description {
  text-align: center;
  color: $color-grey;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: $font-size-lg;
  font-weight: 300;
}
