.btn-primary-custom {
  color: #ffffff;
  background-color: #6196fe;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  border-color: #6196fe; }

.btn-primary-custom:hover {
  transition: background .2s;
  background-color: #8daffe;
  border-color: #6196fe; }

.btn-primary-custom:focus,
.btn-primary-custom:active,
.btn-primary-custom:disabled {
  background-color: #8daffe !important;
  border-color: #6196fe !important;
  box-shadow: none; }

.btn-primary-custom-gradient {
  color: #ffffff;
  background-image: linear-gradient(to right, #8daffe, #6196fe);
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-default-custom {
  color: #2e3239;
  background-color: #fff;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  border: 1px solid #ddd; }

.btn-warning-custom {
  color: #2e3239;
  background-color: #fdeda3;
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-secondary-custom {
  color: #ffffff;
  background-color: #2e3239;
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.btn-secondary-custom:hover,
.btn-secondary-custom:focus {
  transition: background .2s;
  background-color: #999999; }

.containerWithCarousel {
  background: #ffffff !important;
  padding: 1.125rem 1.875rem !important; }

.flex {
  display: flex; }
  @media (max-width: 768px) {
    .flex {
      width: 100%; } }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-grow {
  flex-grow: 1; }

/* recording app */
.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-30 {
  flex: 30; }

.margin-top-lg {
  margin-top: 1.875rem; }

.margin-top {
  margin-top: 1.5rem; }

.margin-bottom-lg {
  margin-bottom: 1.875rem; }

.margin-bottom {
  margin-bottom: 1.5rem; }

.center-items {
  display: flex;
  justify-content: center;
  align-items: center; }

.custom-voilet-button {
  background-color: #9150bc;
  border-radius: 30px;
  padding: .2em;
  color: white;
  border: 0px;
  padding: .2em .9em; }

.bg-voilet {
  background-color: #9150bc; }

.resume-parser__background-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/rbmh-platforms/image/upload/w_1000,c_fill,g_auto/rbjobs/staging/TechnologyFocus.jpg");
  height: 250px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 992px) {
    .resume-parser__background-image {
      height: 350px; } }
  .resume-parser__background-image img {
    max-width: 250px;
    width: 250px; }
    @media (min-width: 992px) {
      .resume-parser__background-image img {
        max-width: 350px;
        width: 350px; } }

.resume-parser__parsed-results--header {
  padding-top: 1.125rem;
  text-align: center;
  border-top: 1px solid #ddd; }

.resume-parser__parsed-results--section-header {
  text-transform: uppercase;
  padding-top: 1.125rem; }

.resume-parser__parsed-results--experience-div {
  border-bottom: 1px solid #ddd;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem; }

.resume-parser__parsed-results--experience-div:last-of-type {
  border-bottom: 0; }

.resume-parser__parsed-results--badge {
  margin-top: 0.75rem;
  margin-right: 0.75rem;
  background: #ccc;
  padding: 0.75rem;
  border-radius: 5px;
  display: inline-block; }

.resume-parser__parsed-results--score-description {
  margin-top: 1.125rem;
  line-height: 1 !important;
  color: #999; }

.resume-parser__dropzone {
  border-radius: 5px;
  text-align: center;
  background: #eee;
  color: #2e3239;
  cursor: pointer;
  border: 1px dashed #ccc; }

.resume-parser__dropzone-active {
  border: 1px solid #bbb;
  background: #ccc; }

.resume-parser__dropzone--div,
.resume-parser__dropzone--div:focus,
.resume-parser__dropzone--div:active {
  outline: 0; }

.resume-parser__dropzone--div-padding {
  padding: 30px; }

.apply-screen-div {
  min-height: 100vh; }
