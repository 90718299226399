.rootContainer {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.topText {
  // margin-left: 3.125rem;
  // width: fit-content;
  font-weight: 500;
  font-size: 1.5em;
  // line-height: 2.4375rem;
  display: flex;
  align-items: center;
  letter-spacing: -.03em;
  color: #16182F;
  white-space: nowrap;
}

.cardFlexContainer {
  display: flex;
  justify-content: space-between;
  // margin-left: 3.125rem;
  // margin-top: 1.5625rem;
  // margin-right: 3.125rem;
}

.cardContainer {
  // width: 33.33%;
  flex: 1;
  flex: 1 1;
  padding: 1em 4em 1em 0;
}


.card {
  display: flex;
  align-items: baseline;
  padding: 0px;
  gap: 1.875rem;
  order: 0;
  flex-grow: 0;
  margin-bottom: 2rem;
}

.progressBar {
  width: 100%;
  border-radius: 2px;
  height: .25rem;
}

.progressBar::-webkit-progress-value {
  width: 100%;
  background: #6196FE;
  border-radius: 2px;
  height: .25rem;
}

.progressBar::-webkit-progress-bar {
  width: 100%;
  background: #EEF0F5;;
  border-radius: 2px;
  height: .25rem;
}


.imageContainer {
  box-sizing: border-box;
  background: rgba(97, 150, 254, .1);
  border: 1px solid rgba(97, 150, 254, .3);
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1.4rem 1.33rem;
}

.chevronButton:focus {
  outline: none;
}



.cardIcon {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}


.cardText {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  letter-spacing: -.03em;
  color: #16182F;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.accordianButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;  
  gap: .6em;

  button {
    background-color: #e4e8f0;
    border-radius: 50%;
    border: 0px;
    color: #5f6981;
    font-size: 1em;
    padding: .2em .3em;
  }

  p {
    margin-bottom: 0;
  }
}

.aiScoreBreakdownScores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 3em;
}



.breakDown {
  display: flex;
  justify-content: space-between;
}


@media screen and (max-width: 750px) {
  
  .cardFlexContainer {
    display: block;
  }
  
  .cardContainer {
    padding: 1.5625rem;
    gap: 1.25rem;
    width: 21.4375rem;
    background: #FFFFFF;
    border-radius: 1.875rem;
    order: 0;
    flex-grow: 0;
  }

  .rootContainer {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
  }

  .topText {
    display: none;
  }

  .cardContainer {
    padding: 1.25rem;
    margin-top: 1.25rem;
    width: 100%;
  }

  .card {
    margin-bottom: 1rem;
  }
}

.activeAccordianBody {
  margin-top: 1em;
}