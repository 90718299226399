
.flex-col-gap1 {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}

.f-w-bold {
  font-weight: bold;
}

//- App drawer svg styles

.navContainer {

  .active {
    border-right: 2px solid #FE5819 !important;
    color: #FE5819 !important;

    .menyIcon {
      stroke: red;
    }
  }
}

.menyIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  stroke: #5F6981;
}

.stripeInputContainer {
  padding: .8rem 1rem 0px 1rem!important;
  border-radius: 12px !important;
  border: 1px solid #7989b1 !important;
  height: 3rem !important;
  margin-top: .5em;
}

.mt-1 {
  margin-top: 1em !important;
}

.flex-wrap {
  display: flex!important;
  flex-wrap: wrap;
}

.w-100 {
  width: 100%;
}

.self-baseline {
  align-self: baseline;
}

.flexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gap-1-em {
  gap: 1em;
}

// .breakDownProgressBar {
//   height: 4px;
//   width: 100%;
//   background: #7989B1;
//   border-radius: 2px;
// }

%breakDownProgressBar {
  background-color: #5cb85c;
  height: .3em;
  background: #7989B1;
  border-radius: 4px;
}


.success {
  background: #5cb85c;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.success::-webkit-progress-value {
  background: #5cb85c;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.success::-moz-progress-bar { 
  background: #EDF0F5;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

progress::-webkit-progress-bar {
  background: #EDF0F5;
}

.danger {
  background: #fd6144;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.danger::-webkit-progress-value {
  background: #fd6144;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.danger::-moz-progress-bar { 
  background: #fd6144;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.warning {
  background: #f0ad4e;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.warning::-webkit-progress-value {
  background: #f0ad4e;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}

.warning::-moz-progress-bar { 
  background: #f0ad4e;
  border-radius: 4px;
  height: .3em;

  @extend %breakDownProgressBar;
}